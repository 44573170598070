export const calculateTextHeight = (text: string, width: number) => {
  const dummyElement = document.createElement("div");
  dummyElement.style.width = width + "px";
  dummyElement.style.height = "auto";
  dummyElement.style.position = "absolute";
  dummyElement.style.visibility = "hidden";
  dummyElement.style.whiteSpace = "pre-wrap";
  dummyElement.style.fontSize = "14px";
  dummyElement.style.lineHeight = "1";
  dummyElement.innerText = text;

  document.body.appendChild(dummyElement);
  const height = dummyElement.offsetHeight;
  document.body.removeChild(dummyElement);

  return height;
};
