import * as React from "react";
import { useStore } from "react-redux";

export const withTranslations = (Component) => {
  const Wrapper = (props) => {
    const store = useStore();
    const translations = store.getState().translations;

    return <Component translations={translations} {...props} />;
  };

  return Wrapper;
};

export default withTranslations;
