import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const history = useNavigate();

    return <Component navigate={history} {...props} />;
  };

  return Wrapper;
};

type Props = {
  to: string;
  children: React.ReactNode;
};

const LinkButton = (props: Props) => {
  const {
    navigate,
    match,
    staticContext,
    to,
    onClick,
    // ⬆ filtering out props that `button` doesn’t know what to do with.
    ...rest
  } = props;
  return (
    <Button
      {...rest} // `children` is just another prop!
      onClick={(event) => {
        onClick && onClick(event);
        navigate(to);
      }}
    />
  );
};

export default withRouter(LinkButton);
