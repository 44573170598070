const humanId = (sequentialId, type) => {
  if (!sequentialId) return null;

  const id = String(sequentialId).padStart(4, '0');

  switch (type) {
    case 'Supplier':
      return `SU${id}`;
    case 'Input':
      return `IP${id}`;
    case 'Output':
      return `OP${id}`;
    case 'Customer':
      return `CU${id}`;
    case 'EventTrigger':
      return `ET${id}`;
    case 'EventCompletion':
      return `EC${id}`;
    // Processes
    case 'main':
      return `MP${id}`;
    case 'normal':
      return `PR${id}`;
    case 'sub':
      return `SP${id}`;
  }
};

export default humanId;
