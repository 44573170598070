import * as React from "react";
import { Enterprise, Translations } from "../../helper/types";
import withTranslations from "../../helper/withTranslations";
import { ImportOutlined } from "@ant-design/icons";

type Props = {
  enterprise?: Enterprise;
  translations: Translations;
};

const CloneButton = ({ enterprise, translations }: Props) => {
  if (!enterprise) return null;

  return (
    <a
      href={enterprise.cloning_url}
      className="bg-white rounded flex items-center uppercase hover:bg-white cursor-pointer px-4 py-2 gap-3"
    >
      <ImportOutlined />
      {translations["navigation.clone.button"]}
    </a>
  );
};

export default withTranslations(CloneButton);
