import { Breadcrumb, Empty } from "antd";
import * as React from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Report } from "../helper/types";
import withTranslations from "../helper/withTranslations";

const ReportsShow = ({ translations, reports }) => {
  const { id } = useParams();

  if (!reports) return null;

  const report = reports.find((report: Report) => report.id === id);

  if (!report) return <Empty className="m-10" />;

  return (
    <div className="p-3 flex flex-col flex-grow">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/reports">{translations["reports.title"]}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item key={report.id}>{report.name}</Breadcrumb.Item>
      </Breadcrumb>

      <div className="mt-4 flex flex-col flex-grow">
        <iframe src={report.iframe_url} className="mt-4 flex-grow" />
      </div>
    </div>
  );
};

export default connect(({ reports }) => ({ reports }))(
  withTranslations(ReportsShow)
);
