import React from 'react';
import { Provider } from 'react-redux';
import configureStore from '../store/frontendStore';

const withStore = (Component) => {
  const Wrapper = (props) => {
    const store = configureStore(props);

    return (
      <Provider store={store}>
        <Component {...props} />
      </Provider>
    );
  };

  return Wrapper;
};

export default withStore;
