import React from "react";
import withTranslations from "../helper/withTranslations";
import { Graph } from "@antv/x6";
import { usePortal } from "@antv/x6-react-shape";
import "./sipoc/DocumentCurvy";
import DocumentCurvy from "./sipoc/DocumentCurvy";
import "./sipoc/Data";
import Data from "./sipoc/Data";
import "./sipoc/ValueChain";
import ValueChain from "./sipoc/ValueChain";
import "./sipoc/SupplierCustomer";
import SupplierCustomer from "./sipoc/SupplierCustomer";
import "./sipoc/Event";
import Event from "./sipoc/Event";
import "./sipoc/Process";
import Process from "./sipoc/Process";
import humanId from "../helper/human_id";
import { compareByProcessName, predecessorSort } from "../helper/processes";
import { connect } from "react-redux";
import { FrontendSequence } from "../helper/types";
import { calculateTextHeight } from "../helper/textCalculations";

const shapeForDataType = (type) => {
  switch (type) {
    case "document":
      return "DocumentCurvy";
    case "data":
      return "Data";
    case "value_chain":
      return "ValueChain";
    default:
      return "DocumentCurvy";
  }
};

const displayZoom = 4;
const viewBoxWidth = 297 * displayZoom;
const viewBoxHeight = 210 * displayZoom;
const columnGap = 25;
const padding = 10;
const columnWidth = (viewBoxWidth - 4 * columnGap - 2 * padding) / 5;
const sequenceHeight = 75;
const rowGap = 10;

const numberOfRenderedSequences = (process, sequences) => {
  const isEventRendered =
    (process.trigger && process.trigger.name.trim().length > 0) ||
    (process.completion && process.completion.name.trim().length > 0);
  const eventIndexOffset = isEventRendered ? 1 : 0;

  // There is an empty row to enter new elements
  const numberOfSioc = Object.values(sequences).filter(
    (sequence: FrontendSequence) =>
      (sequence.supplier && sequence.supplier.name.trim().length > 0) ||
      (sequence.input && sequence.input.name.trim().length > 0) ||
      (sequence.output && sequence.output.name.trim().length > 0) ||
      (sequence.customer && sequence.customer.name.trim().length > 0)
  ).length;
  const numberOfLines = Math.max(1, numberOfSioc + eventIndexOffset);

  return { eventIndexOffset, numberOfLines };
};

const renderProcess = (
  process,
  graph,
  enterpriseName,
  sortedProcesses,
  allProcesses,
  index,
  yOffset = 0
) => {
  const elements = Object.values(process.sipocElements || {});
  const elementsToRender = elements;
  const { eventIndexOffset, numberOfLines } = numberOfRenderedSequences(
    process,
    process.sipocElements
  );

  // Process
  const processNodeX = padding + 2 * (columnWidth + columnGap);
  const processNodeXRight = processNodeX + columnWidth;
  const processOwnerHeight = calculateTextHeight(
    process.process_owner,
    columnWidth
  );
  const processNode = graph.addNode({
    shape: "Process",
    x: processNodeX,
    y: padding + sequenceHeight + rowGap + yOffset,
    width: columnWidth,
    height: processOwnerHeight + numberOfLines * (sequenceHeight + rowGap),
    label: process.name,
    process_owner: process.process_owner,
    human_id: humanId(process.sequential_id, process.process_level),
  });

  const parent = process.parent_frontend_id
    ? allProcesses.find((p) => p.frontend_id === process.parent_frontend_id)
    : null;
  const currentProcessIndex = sortedProcesses.findIndex(
    (p) => p.frontend_id === process.frontend_id
  );
  const previousProcessIndex = currentProcessIndex - 1;
  const previousProcess =
    previousProcessIndex >= 0 ? sortedProcesses[previousProcessIndex] : null;

  if (index === 0) {
    // Enterprise name
    graph.addNode({
      x: padding,
      y: padding + yOffset,
      width: columnWidth,
      height: sequenceHeight,
      label: enterpriseName,
      attrs: {
        label: {
          fontSize: 25,
          fontWeight: "bold",
          textAnchor: "start",
          dx: -columnWidth / 2,
        },
      },
      markup: [
        {
          tagName: "text",
          selector: "label",
        },
      ],
    });
  }

  if (
    index === 0 ||
    (previousProcess &&
      previousProcess.parent_frontend_id !== process.parent_frontend_id)
  ) {
    // Parent process
    if (parent) {
      graph.addNode({
        x: padding + 5 * (columnWidth + columnGap) - columnGap,
        y: padding + yOffset,
        width: columnWidth,
        height: sequenceHeight,
        label: `${humanId(parent.sequential_id, parent.process_level)} ${
          parent.name
        }`,
        attrs: {
          label: {
            fontSize: 25,
            fontWeight: "bold",
            textAnchor: "end",
            dx: -columnWidth / 2,
          },
        },
        markup: [
          {
            tagName: "text",
            selector: "label",
          },
        ],
      });
    }
  }

  // Event trigger
  if (process.trigger && process.trigger.name !== "") {
    const eventTriggerY = padding + (sequenceHeight + rowGap) + yOffset;

    const eventtrigger = graph.addNode({
      shape: "Event",
      x: padding + columnWidth + columnGap,
      y: eventTriggerY,
      width: columnWidth,
      height: sequenceHeight,
      label: process.trigger.name,
      human_id: humanId(process.trigger.sequential_id, "EventTrigger"),
    });

    graph.addEdge({
      source: eventtrigger,
      target: { x: processNodeX, y: eventTriggerY + sequenceHeight / 2 },
    });
  }

  // Event completion
  if (process.completion && process.completion.name !== "") {
    const eventCompletionY = padding + (sequenceHeight + rowGap) + yOffset;

    const eventcompletion = graph.addNode({
      shape: "Event",
      x: padding + 3 * (columnWidth + columnGap),
      y: eventCompletionY,
      width: columnWidth,
      height: sequenceHeight,
      label: process.completion.name,
      human_id: humanId(process.completion.sequential_id, "EventCompletion"),
    });

    graph.addEdge({
      source: {
        x: processNodeXRight,
        y: eventCompletionY + sequenceHeight / 2,
      },
      target: eventcompletion,
    });
  }

  elementsToRender.map((sequence, index) => {
    let supplier, input, output, customer;

    if (sequence.supplier && sequence.supplier.name != "") {
      supplier = graph.addNode({
        shape: "SupplierCustomer",
        x: padding,
        y:
          padding +
          (index + eventIndexOffset + 1) * (sequenceHeight + rowGap) +
          yOffset,
        width: columnWidth,
        height: sequenceHeight,
        label: sequence.supplier.name,
        human_id: humanId(sequence.supplier.sequential_id, "Supplier"),
      });
    }

    if (sequence.input && sequence.input.name != "") {
      const inputY =
        padding +
        (index + eventIndexOffset + 1) * (sequenceHeight + rowGap) +
        yOffset;

      input = graph.addNode({
        shape: shapeForDataType(sequence.input.document_type),
        x: padding + columnWidth + columnGap,
        y: inputY,
        width: columnWidth,
        height: sequenceHeight,
        label: sequence.input.name,
        human_id: humanId(sequence.input.sequential_id, "Input"),
      });

      graph.addEdge({
        source: input,
        //target: processNode,
        target: { x: processNodeX, y: inputY + sequenceHeight / 2 },
      });
    }
    if (sequence.output && sequence.output.name != "") {
      const outputY =
        padding +
        (index + eventIndexOffset + 1) * (sequenceHeight + rowGap) +
        yOffset;

      output = graph.addNode({
        shape: shapeForDataType(sequence.output.document_type),
        x: padding + 3 * (columnWidth + columnGap),
        y: outputY,
        width: columnWidth,
        height: sequenceHeight,
        label: sequence.output.name,
        human_id: humanId(sequence.output.sequential_id, "Output"),
      });

      graph.addEdge({
        //source: processNode,
        source: { x: processNodeXRight, y: outputY + sequenceHeight / 2 },
        target: output,
      });
    }
    if (sequence.customer && sequence.customer.name != "") {
      customer = graph.addNode({
        shape: "SupplierCustomer",
        x: padding + 4 * (columnWidth + columnGap),
        y:
          padding +
          (index + eventIndexOffset + 1) * (sequenceHeight + rowGap) +
          yOffset,
        width: columnWidth,
        height: sequenceHeight,
        label: sequence.customer.name,
        human_id: humanId(sequence.customer.sequential_id, "Customer"),
      });
    }

    if (supplier && input) {
      graph.addEdge({
        source: supplier,
        target: input,
      });
    }

    if (output && customer) {
      graph.addEdge({
        source: output,
        target: customer,
      });
    }
  });

  processNode.process = process;

  return processNode;
};

const pairwise = (arr, func) => {
  for (var i = 0; i < arr.length - 1; i++) {
    func(arr[i], arr[i + 1]);
  }
};

const Sipoc = ({ processes, allProcesses, enterprise, firstPaneSize }) => {
  const uniqueGraphId = `sipoc-${Date.now()}`;
  const [Portal, setGraph] = usePortal(uniqueGraphId);
  const maxHeight = (process) => {
    const { numberOfLines } = numberOfRenderedSequences(
      process,
      process.sipocElements
    );

    return (numberOfLines + 1) * (sequenceHeight + rowGap);
  };

  const renderGraph = () => {
    const container = document.getElementById("sipocContainer");
    container.innerHTML = "";

    const graph = new Graph({
      container,
      grid: false,
      panning: {
        enabled: false,
      },
      interacting: false,
    });
    setGraph(graph);

    let currentYOffset = 0;
    const scale = (container.offsetWidth - 2 * padding) / viewBoxWidth;

    const sortedProcesses = predecessorSort(
      processes.sort(compareByProcessName)
    );
    const processNodes = sortedProcesses.map((process, index) => {
      const processNode = renderProcess(
        process,
        graph,
        enterprise.name,
        sortedProcesses,
        allProcesses,
        index,
        currentYOffset
      );

      currentYOffset += maxHeight(process);

      return processNode;
    });

    pairwise(processNodes, (current, next) => {
      if (
        current.process.frontend_id === next.process.predecessor_frontend_id ||
        (current.process.predecessor_frontend_id &&
          current.process.predecessor_frontend_id ===
            next.process.predecessor_frontend_id)
      ) {
        graph.addEdge({
          source: current,
          target: next,
        });
      }
    });

    const contentHeight = currentYOffset + sequenceHeight;

    graph.scaleContentToFit({
      minScale: scale,
      maxScale: scale,
      padding,
      contentArea: { x: 0, y: 0, width: viewBoxWidth, height: contentHeight },
      viewportArea: {
        x: 0,
        y: 0,
        width: container.offsetWidth,
        height: container.offsetHeight,
      },
    });

    const pixelHeight = `${Math.ceil(contentHeight * scale)}px`;
    container.style.minHeight = pixelHeight;
    container.style.height = pixelHeight;

    const svg = container.querySelector("svg");
    const width = container.style.width.replace("px", "");
    svg.setAttribute(
      "viewBox",
      `0 0 ${width} ${pixelHeight.replace("px", "")}`
    );
    // fix to remove extra whitespace at top of svg in print view and when resizing page
    svg.setAttribute("preserveAspectRatio", "xMinYMin meet");
  };

  React.useEffect(() => {
    // We have to render the graph twice as otherwise relative positioning does not work.
    renderGraph();
    renderGraph();
  }, [processes]);

  return (
    <div id="sipocContainer">
      <Portal />
    </div>
  );
};

const mapStateToProps = ({ processes, enterprises }) => ({
  allProcesses: processes,
  enterprise: enterprises.current,
});

export default connect(mapStateToProps)(withTranslations(Sipoc));
