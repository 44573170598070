import humanId from "./human_id";
import { array as toposortArray } from "toposort";

const compareByString = (iteratee) => (a, b) => {
  const iteratedA = iteratee(a);
  const iteratedB = iteratee(b);

  if (iteratedA < iteratedB) {
    return -1;
  }

  if (iteratedA > iteratedB) {
    return 1;
  }

  return 0;
};

const processNameWithId = (process) =>
  `${humanId(process.sequential_id, process.process_level) || ""} ${
    process.name
  }`;

const compareByProcessName = compareByString((process) =>
  processNameWithId(process)
);

const predecessorSort = (processes) => {
  const predecessor = (process) =>
    processes.find((p) => p.frontend_id === process.predecessor_frontend_id);

  var edges = processes
    .filter((process) => process.predecessor_frontend_id)
    .map((process) =>
      predecessor(process)
        ? [predecessor(process).frontend_id, process.frontend_id]
        : null
    )
    .filter(Boolean);

  const topologicalSortedFrontendIds = toposortArray(
    processes.map((process) => process.frontend_id),
    edges
  );

  return topologicalSortedFrontendIds.map((frontend_id) =>
    processes.find((process) => process.frontend_id === frontend_id)
  );
};

export {
  compareByString,
  processNameWithId,
  compareByProcessName,
  predecessorSort,
};
