import { call, put, takeEvery, all } from "redux-saga/effects";
import Api from "./api";
import Const from "../constants/frontendConstants";
import { SipocElement } from "../helper/types";

function* fetchProcesses(action) {
  const timestamp = Date.now();

  try {
    yield put({
      type: "apirequest_started",
      payload: { timestamp, requestType: "fetchProcesses" },
    });
    const processes = yield call(
      Api.fetchProcesses,
      action.payload.enterpriseId
    );
    yield put({ type: "processes_fetched", processes });
    if (!action.payload.readOnly) {
      yield put({
        type: "sipocelements_autocomplete_fetch",
        payload: { enterpriseId: action.payload.enterpriseId },
      });
    }
    yield put({ type: "apirequest_stopped", payload: { timestamp } });
  } catch (e) {
    window.airbrake.notify({ error: e, params: { action } });
    console.error(e);
    yield put({ type: "apirequest_stopped", payload: { timestamp } });
    yield put({ type: "error" });
  }
}

function* updateProcess(action) {
  const timestamp = Date.now();

  try {
    yield put({
      type: "apirequest_started",
      payload: { timestamp, requestType: "updateProcess" },
    });
    yield put({ type: "process_updated", process: action.payload.process });
    const process = yield call(
      Api.updateProcess,
      action.payload.enterpriseId,
      action.payload.process
    );
    yield put({ type: "process_updated", process });
    yield put({ type: "apirequest_stopped", payload: { timestamp } });
  } catch (e) {
    window.airbrake.notify({ error: e, params: { action } });
    console.error(e);
    yield put({ type: "apirequest_stopped", payload: { timestamp } });
    yield put({ type: "error" });
  }
}

function* deleteProcess(action) {
  const timestamp = Date.now();

  try {
    yield put({
      type: "apirequest_started",
      payload: { timestamp, requestType: "deleteProcess" },
    });
    yield call(
      Api.deleteProcess,
      action.payload.enterpriseId,
      action.payload.processFrontendId
    );
    yield put({
      type: "process_deleted",
      processFrontendId: action.payload.processFrontendId,
    });
    yield put({
      type: "sipocelements_autocomplete_fetch",
      payload: { enterpriseId: action.payload.enterpriseId },
    });
    yield put({ type: "apirequest_stopped", payload: { timestamp } });
    action.payload.navigate();
  } catch (e) {
    window.airbrake.notify({ error: e, params: { action } });
    console.error(e);
    yield put({ type: "apirequest_stopped", payload: { timestamp } });
    yield put({ type: "error" });
  }
}

function* updateSipocElement(action) {
  const timestamp = Date.now();

  try {
    yield put({
      type: "apirequest_started",
      payload: { timestamp, requestType: "updateSipocElement" },
    });
    const sipocElement = action.payload.sipocElement;

    yield put({ type: "sipocelement_updated", sipocElement });
    const updatedSipocElement = yield call(
      Api.updateSipocElement,
      action.payload.enterpriseId,
      action.payload.processFrontendId,
      sipocElement
    );
    yield put({
      type: "sipocelement_updated",
      sipocElement: {
        ...updatedSipocElement,
        key: updatedSipocElement.frontend_id,
      },
    });

    yield put({
      type: "sipocelements_autocomplete_fetch",
      payload: { enterpriseId: action.payload.enterpriseId },
    });
    yield put({ type: "apirequest_stopped", payload: { timestamp } });
  } catch (e) {
    window.airbrake.notify({ error: e, params: { action } });
    console.error(e);
    yield put({ type: "apirequest_stopped", payload: { timestamp } });
    yield put({ type: "error" });
  }
}

function* fetchSipocElements(action) {
  const timestamp = Date.now();

  try {
    yield put({
      type: "apirequest_started",
      payload: { timestamp, requestType: "fetchSipocElements" },
    });
    const sipocElements: SipocElement[] = yield call(
      Api.fetchSipocElements,
      action.payload.enterpriseId,
      action.payload.processFrontendId
    );
    yield put({
      type: "sipocelements_fetched",
      payload: { sipocElements, readOnly: action.payload.readOnly },
    });
    yield put({ type: "apirequest_stopped", payload: { timestamp } });
  } catch (e) {
    window.airbrake.notify({ error: e, params: { action } });
    console.error(e);
    yield put({ type: "apirequest_stopped", payload: { timestamp } });
    yield put({ type: "error" });
  }
}

function* deleteSipocElement(action) {
  const timestamp = Date.now();

  try {
    yield put({
      type: "apirequest_started",
      payload: { timestamp, requestType: "deleteSipocElement" },
    });
    const deletedElements = yield call(
      Api.deleteSipocElement,
      action.payload.enterpriseId,
      action.payload.processFrontendId,
      action.payload.sipocElementId
    );

    yield put({ type: "sipocelement_deleted", payload: deletedElements });
    yield put({
      type: "sipocelements_fetch",
      payload: {
        enterpriseId: action.payload.enterpriseId,
        processFrontendId: action.payload.processFrontendId,
        readOnly: false,
      },
    });
    yield put({
      type: "sipocelements_autocomplete_fetch",
      payload: { enterpriseId: action.payload.enterpriseId },
    });
    yield put({ type: "apirequest_stopped", payload: { timestamp } });
  } catch (e) {
    window.airbrake.notify({ error: e, params: { action } });
    console.error(e);
    yield put({ type: "apirequest_stopped", payload: { timestamp } });
    yield put({ type: "error" });
  }
}

function* updateEvent(action) {
  const timestamp = Date.now();

  try {
    yield put({
      type: "apirequest_started",
      payload: { timestamp, requestType: "updateEvent" },
    });
    const process = yield call(
      Api.updateEvent,
      action.payload.enterpriseId,
      action.payload.processFrontendId,
      action.payload.event
    );
    yield put({ type: "process_updated", process });
    yield put({ type: "apirequest_stopped", payload: { timestamp } });
  } catch (e) {
    window.airbrake.notify({ error: e, params: { action } });
    console.error(e);
    yield put({ type: "apirequest_stopped", payload: { timestamp } });
    yield put({ type: "error" });
  }
}

function* deleteEvent(action) {
  const timestamp = Date.now();

  try {
    yield put({
      type: "apirequest_started",
      payload: { timestamp, requestType: "updateEvent" },
    });
    const process = yield call(
      Api.deleteEvent,
      action.payload.enterpriseId,
      action.payload.processFrontendId,
      action.payload.event.eventType
    );
    yield put({ type: "process_updated", process });
    yield put({ type: "apirequest_stopped", payload: { timestamp } });
  } catch (e) {
    window.airbrake.notify({ error: e, params: { action } });
    console.error(e);
    yield put({ type: "apirequest_stopped", payload: { timestamp } });
    yield put({ type: "error" });
  }
}

function* fetchSipocElementsAutocomplete(action) {
  const timestamp = Date.now();

  try {
    yield put({
      type: "apirequest_started",
      payload: { timestamp, requestType: "fetchSipocElementsAutocomplete" },
    });
    const elements = yield call(
      Api.fetchSipocElementsAutocomplete,
      action.payload.enterpriseId
    );

    yield put({
      type: "sipocelements_autocomplete_fetched",
      payload: elements,
    });
    yield put({ type: "apirequest_stopped", payload: { timestamp } });
  } catch (e) {
    window.airbrake.notify({ error: e, params: { action } });
    console.error(e);
    yield put({ type: "apirequest_stopped", payload: { timestamp } });
    yield put({ type: "error" });
  }
}

function* fetchEventsAutocomplete(action) {
  const timestamp = Date.now();

  try {
    yield put({
      type: "apirequest_started",
      payload: { timestamp, requestType: "fetchEventsAutocomplete" },
    });
    const elements = yield call(
      Api.fetchEventsAutocomplete,
      action.payload.enterpriseId,
      action.payload.processLevel
    );

    yield put({ type: "events_autocomplete_fetched", payload: elements });
    yield put({ type: "apirequest_stopped", payload: { timestamp } });
  } catch (e) {
    window.airbrake.notify({ error: e, params: { action } });
    console.error(e);
    yield put({ type: "apirequest_stopped", payload: { timestamp } });
    yield put({ type: "error" });
  }
}

function* fetchReports(action) {
  const timestamp = Date.now();

  try {
    yield put({
      type: "apirequest_started",
      payload: { timestamp, requestType: "fetchReports" },
    });
    const reports = yield call(Api.fetchReports, action.payload.enterpriseId);

    yield put({ type: "reports_fetched", payload: reports });
    yield put({ type: "apirequest_stopped", payload: { timestamp } });
  } catch (e) {
    window.airbrake.notify({ error: e, params: { action } });
    console.error(e);
    yield put({ type: "apirequest_stopped", payload: { timestamp } });
    yield put({ type: "error" });
  }
}

function* sagas() {
  yield all([
    takeEvery("processes_fetch", fetchProcesses),
    takeEvery("process_update", updateProcess),
    takeEvery("process_delete", deleteProcess),
    takeEvery("sipocelement_update", updateSipocElement),
    takeEvery("sipocelements_fetch", fetchSipocElements),
    takeEvery("sipocelement_delete", deleteSipocElement),
    takeEvery(
      "sipocelements_autocomplete_fetch",
      fetchSipocElementsAutocomplete
    ),
    takeEvery("events_autocomplete_fetch", fetchEventsAutocomplete),
    takeEvery("event_update", updateEvent),
    takeEvery("event_delete", deleteEvent),
    takeEvery("reports_fetch", fetchReports),
  ]);
}

export default sagas;
