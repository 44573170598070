import * as React from "react";
import { Breadcrumb, Tag } from "antd";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getParents } from "../../helper/parent_processes";
import humanId from "../../helper/human_id";

const Breadcrumbs = ({ enterprises, processes }) => {
  const { id } = useParams();
  if (!id) return null;

  const currentProcess = processes.find(
    (process) => process.frontend_id === id
  );

  if (!currentProcess) return null

  const breadcrumbProcesses = [
    ...getParents(processes, currentProcess),
    currentProcess,
  ];

  return (
    <Breadcrumb>
      <Breadcrumb.Item>{enterprises.current.name}</Breadcrumb.Item>
      {breadcrumbProcesses.map((process) => (
        <Breadcrumb.Item key={process.frontend_id}>
          <Link to={`/processes/${process.frontend_id}`}>
            <Tag className="tag-id" style={{ marginRight: "0px" }}>
              {humanId(process.sequential_id, process.process_level)}
            </Tag>{" "}
            {process.name}
          </Link>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

const mapStateToProps = ({ enterprises, processes }) => ({
  enterprises,
  processes,
});

export default connect(mapStateToProps)(Breadcrumbs);
