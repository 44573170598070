export const getParents = (processes, process, parents = []) => {
  if (process.parent_frontend_id) {
    const parent = processes.find(
      (p) => p.frontend_id === process.parent_frontend_id
    );

    if (parent) {
      parents.push(parent);

      return getParents(processes, parent, parents);
    } else {
      window.airbrake.notify({
        error: new Error(
          `Supposed to find a parent "${process.parent_frontend_id}", but did not find it.`
        ),
        params: { processes, process, parents },
      });
    }
  }

  return parents;
};

export const filterParentsForProcessLevel = (processes, processLevel) => {
  if (!processes) return null;

  switch (processLevel) {
    case "normal":
      return processes.filter((process) => process.process_level === "main");
    case "main":
      return null;
    case "sub":
      return processes.filter((process) => process.process_level === "normal");
    default:
      return null;
  }
};

export const updateProcessesList =
  (
    form,
    setProcessLevel,
    setFilteredProcessesForPredecessor,
    processes,
    process
  ) =>
  (changedValues, allValues) => {
    if (changedValues.process_level) {
      form.setFieldsValue({ parent_frontend_id: null });
      form.setFieldsValue({ predecessor_frontend_id: null });
    }

    if (changedValues.parent_frontend_id) {
      const predecessors = updatePredecessors(
        changedValues.parent_frontend_id,
        setFilteredProcessesForPredecessor,
        processes,
        allValues.process_level,
        process
      );

      if (
        !predecessors
          .map((process) => process.frontend_id)
          .includes(allValues.updatePredecessors)
      ) {
        form.setFieldsValue({ predecessor_frontend_id: null });
      }
    }

    setProcessLevel(allValues.process_level);
  };

export const updatePredecessors = (
  processes,
  process
) => {
  let predecessors = (processes || []).filter(
    (p) => p.process_level === process.process_level
  );

  predecessors = predecessors.filter(
    (p) => p.frontend_id !== process.frontend_id
  );

  return predecessors;
};
