import * as React from "react";
import Split from "react-split";

const SplitWrapper = ({ splitDirection, children }) => {
  if (splitDirection === "horizontal") {
    return (
      <Split
        sizes={[50, 50]}
        minSize={100}
        expandToMin={false}
        gutterSize={1}
        gutterAlign="center"
        snapOffset={30}
        dragInterval={1}
        direction="horizontal"
        cursor="col-resize"
        style={{
          display: "flex",
          flexDirection: "row",
          flex: "1 1 auto",
          overflow: "hidden",
        }}
      >
        {children}
      </Split>
    );
  }

  return <>{children}</>;
};

export default SplitWrapper;
