import produce from "immer";
import { v4 as uuid } from "uuid";
import {
  FrontendSequence,
  FrontendSequences,
  FrontendSiocSequences,
  FrontendSipocElement,
  Sequence,
  Sequences,
  SIOCSequence,
  SIOCKey,
  FrontendSiocSequence,
  SipocElement,
} from "./types";

const sipocTypes: SIOCKey[] = ["Supplier", "Input", "Output", "Customer"];

const emptyElement = (
  type: SIOCKey,
  sequenceFrontendId: string,
  sequence: number
): FrontendSipocElement => {
  const frontendId = uuid();

  return {
    key: frontendId,
    frontend_id: frontendId,
    name: "",
    type,
    sequence_frontend_id: sequenceFrontendId,
    document_type: type === "Input" || type === "Output" ? "document" : null,
    sequence,
  };
};

const MAX_SEQUENCES = 10;

const emptySequence = (
  sequenceId = uuid(),
  sequencePosition = -1
): FrontendSiocSequence => {
  const newSequence = sequenceId;
  const supplier = emptyElement("Supplier", newSequence, sequencePosition);
  const input = emptyElement("Input", newSequence, sequencePosition);
  const output = emptyElement("Output", newSequence, sequencePosition);
  const customer = emptyElement("Customer", newSequence, sequencePosition);

  return {
    key: newSequence,
    sequence_frontend_id: newSequence,
    sequence: sequencePosition,
    supplier,
    input,
    output,
    customer,
  };
};

const transformSequenceToFrontend = (sequence: Sequence): FrontendSequence => {
  const convertedSequence = sipocTypes.reduce((frontendSequence, sipocType) => {
    const sipocObjectType = sipocType.toLowerCase();
    const element = frontendSequence[sipocObjectType];

    if (element) {
      return {
        ...frontendSequence,
        [sipocObjectType]: {
          ...frontendSequence[sipocObjectType],
          key: frontendSequence[sipocObjectType].frontend_id,
        },
      };
    } else {
      return {
        ...frontendSequence,
        [sipocObjectType]: emptyElement(
          sipocType,
          frontendSequence.sequence_frontend_id,
          frontendSequence.sequence
        ),
        key: frontendSequence.sequence_frontend_id,
      };
    }
  }, sequence);

  return {
    ...convertedSequence,
    key: convertedSequence.sequence_frontend_id,
  };
};

const transformSequencesToFrontend = (
  sequences: Sequences
): FrontendSequences => {
  return Object.values(sequences).reduce((frontendSequences, sequence) => {
    const newSequence = transformSequenceToFrontend(sequence);

    return {
      ...frontendSequences,
      [sequence.sequence_frontend_id]: {
        ...newSequence,
        key: sequence.sequence_frontend_id,
      },
    };
  }, {});
};

const hasEmptyRow = (sequences: FrontendSequences) => !!getEmptyRow(sequences);

const elements = (sequence: SIOCSequence): SipocElement[] => {
  const { supplier, input, output, customer } = sequence;

  return [supplier, input, output, customer];
};

const getEmptyRow = (sequences: FrontendSequences): FrontendSequence | null => {
  const sequenceArray = Object.values(sequences);

  if (sequenceArray.length <= 0) {
    return null;
  }

  return sequenceArray.find((sequence: SIOCSequence) =>
    elements(sequence).every(
      (element: FrontendSipocElement) =>
        (element?.name || "").trim().length <= 0
    )
  );
};

const ensureBlankRow = (
  sequences: FrontendSiocSequences
): FrontendSiocSequences => {
  const sequenceArray = Object.values(sequences);

  if (sequenceArray.length >= MAX_SEQUENCES) {
    return sequences;
  }

  if (hasEmptyRow(sequences)) {
    return sequences;
  } else {
    const nextIndex =
      Math.max(
        ...sequenceArray
          .map((sequence) => [
            sequence.supplier?.sequence || 0,
            sequence.input?.sequence || 0,
            sequence.output?.sequence || 0,
            sequence.customer?.sequence || 0,
          ])
          .flat()
      ) + 1;
    const emptyRow = emptySequence(
      uuid(),
      Math.max(nextIndex, sequenceArray.length + 1)
    );

    return {
      ...sequences,
      [emptyRow.sequence_frontend_id]: emptyRow,
    };
  }
};

const replaceSipocElement = (
  sequences: FrontendSequences,
  element: SipocElement,
  replacedElement: SipocElement | null = null
) => {
  return produce(sequences || {}, (draft) => {
    const sequence: Sequence | null = draft[element.sequence_frontend_id];

    if (sequence) {
      const oldElement =
        draft[element.sequence_frontend_id][element.type.toLowerCase()];

      draft[element.sequence_frontend_id][element.type.toLowerCase()] =
        replacedElement ? replacedElement : { ...oldElement, ...element };
    } else {
      const emptyRow = getEmptyRow(sequences);

      if (emptyRow) {
        const newElement = replacedElement ? replacedElement : element;
        draft[emptyRow.sequence_frontend_id][element.type.toLowerCase()] = {
          ...newElement,
          sequence: emptyRow.sequence,
          sequence_frontend_id: emptyRow.sequence_frontend_id,
        };
      } else {
        const newSequence = emptySequence(
          element.sequence_frontend_id,
          element.sequence
        );

        newSequence[element.type.toLowerCase()] = element;

        draft[element.sequence_frontend_id] = newSequence;
      }
    }
  });
};

const fetchElement = (
  sequences: FrontendSequences,
  elementFrontendId: string
): FrontendSipocElement | null => {
  const allElements = Object.values(sequences)
    .map((sequence) => [
      sequence.supplier,
      sequence.input,
      sequence.output,
      sequence.customer,
    ])
    .flat();

  return allElements.find(
    (element) => element.frontend_id === elementFrontendId
  );
};

export {
  emptyElement,
  transformSequencesToFrontend,
  ensureBlankRow,
  emptySequence,
  fetchElement,
  replaceSipocElement,
  hasEmptyRow,
};
