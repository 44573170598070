import { Node } from "@antv/x6";
import { Graph } from "@antv/x6";

const fontSize = 14;

const Process = Node.define({
  width: 100,
  height: 40,
  markup: [
    {
      tagName: "rect",
      selector: "body",
    },
    {
      tagName: "text",
      selector: "label",
    },
    {
      tagName: "text",
      selector: "process_owner",
    },
    {
      tagName: "text",
      selector: "human_id",
    },
    {
      tagName: "line",
      selector: "process_owner_border",
    },
  ],
  attrs: {
    body: {
      fill: "#ffffff",
      stroke: "#3465a4",
      strokeWidth: 3,
    },
    label: {
      fontSize: fontSize,
      fill: "black",
      textAnchor: "middle",
      textVerticalAnchor: "middle",
      textWrap: {
        width: -10,
      },
    },
    human_id: {
      fontSize: 10,
      fill: "black",
      textAnchor: "end",
      textVerticalAnchor: "middle",
    },
    process_owner: {
      fontSize: fontSize,
      fill: "black",
      textAnchor: "middle",
      textVerticalAnchor: "bottom",
      textWrap: {
        width: -10,
      },
    },
    process_owner_border: {
      stroke: "#3465a4",
      strokeWidth: 3,
    },
  },

  propHooks(metadata) {
    const { label, process_owner, human_id, size } = metadata;

    if (label) {
      metadata.attrs = {};
      metadata.attrs.label = {};
      metadata.attrs.label.textWrap = {};
      metadata.attrs.label.textWrap.text = label;
      metadata.attrs.label.refX = size.width / 2;
      metadata.attrs.label.refY = size.height / 2;
      metadata.attrs.body = {};
      metadata.attrs.body.width = size.width;
      metadata.attrs.body.height = size.height;
    }

    if (process_owner) {
      metadata.attrs = metadata.attrs || {};
      metadata.attrs.process_owner = {};
      metadata.attrs.process_owner.textWrap = { text: process_owner };
      metadata.attrs.process_owner.refX = size.width / 2;
      metadata.attrs.process_owner.refY = size.height;
      metadata.attrs.process_owner.yAlign = "bottom";
      metadata.attrs.process_owner.width = size.width;

      metadata.attrs.process_owner_border = {};
      metadata.attrs.process_owner_border.ref = "process_owner";
      metadata.attrs.process_owner_border.refY = -fontSize / 2;
      metadata.attrs.process_owner_border.x1 = 0;
      metadata.attrs.process_owner_border.y1 = 0;
      metadata.attrs.process_owner_border.x2 = size.width;
      metadata.attrs.process_owner_border.y2 = 0;
    }

    if (human_id) {
      metadata.attrs = metadata.attrs || {};
      metadata.attrs.human_id = {};
      metadata.attrs.human_id.text = human_id;
      metadata.attrs.human_id.width = size.width;
      metadata.attrs.human_id.refX = size.width - 4;
      metadata.attrs.human_id.refY = 10;
    }

    return metadata;
  },
});

Graph.registerNode("Process", Process, true);
