import * as React from "react";
import { useMemo } from "react";
import withTranslations from "../helper/withTranslations";
import { Button, Dropdown, Menu, Popconfirm } from "antd";
import Sipoc from "./Sipoc";
import SipocDataTable from "./SipocDataTable";
import EventTable from "./EventTable";
import Breadcrumbs from "./Breadcrumbs/Breadcrumbs";
import ToggleButton from "./ToggleButton/ToggleButton";
import { EllipsisOutlined, PrinterOutlined } from "@ant-design/icons";
import ProcessAttribute from "./ProcessAttribute/ProcessAttribute";
import ProcessLabel from "./ProcessLabel/ProcessLabel";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import humanId from "../helper/human_id";
import {
  filterParentsForProcessLevel,
  updatePredecessors,
} from "../helper/parent_processes";
import { compareByProcessName } from "../helper/processes";
import SplitWrapper from "./SplitWrapper";
import { getProcessAutoCompleteOptions } from "../helper/process_autocomplete";
import { isLite } from "../helper/lite";

const ProcessShow = ({
  enterprise,
  process,
  sipocElements,
  translations,
  readOnly,
}) => {
  return (
    <RenderProcessWithProcesses
      enterprise={enterprise}
      process={process}
      sipocElements={sipocElements}
      translations={translations}
      readOnly={readOnly}
    />
  );
};

const RenderProcess = ({
  user,
  enterprise,
  process,
  sipocElements,
  translations,
  processes,
  readOnly,
}) => {
  // Pane size is used to force rerender when window size changes
  const [firstPaneSize, setFirstPaneSize] = React.useState(null);
  const [splitDirection, setSplitDirection] = React.useState("vertical");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isFullVersion = !isLite(user);

  const processesWithElements = [{ ...process, sipocElements }];

  window.onbeforeprint = () => {
    document
      .getElementById("sipocContainer")
      .querySelector("svg")
      .setAttribute("width", "19cm");
  };
  window.onafterprint = () => {
    document
      .getElementById("sipocContainer")
      .querySelector("svg")
      .setAttribute("width", "auto");
  };
  window.onresize = () => {
    setFirstPaneSize(window.innerWidth);
  };

  const handleChange = (value, name) => {
    if (name === "name" && (!value || value.trim().length <= 0)) return;

    const attributes = { [name]: value };

    if (name === "process_level" && value !== process.process_level) {
      attributes["parent_frontend_id"] = null;
    }

    dispatch({
      type: "process_update",
      payload: {
        enterpriseId: enterprise.token,
        process: {
          frontend_id: process.frontend_id,
          ...attributes,
        },
      },
    });
  };
  const dropdownOptionsProcessLevel = useMemo(
    () => [
      {
        label: translations["enumerize.process.process_level.main"],
        value: "main",
      },
      {
        label: translations["enumerize.process.process_level.normal"],
        value: "normal",
      },
      {
        label: translations["enumerize.process.process_level.sub"],
        value: "sub",
      },
    ],
    []
  );
  const filteredProcessesForParent = filterParentsForProcessLevel(
    processes,
    process.process_level
  )?.sort(compareByProcessName);
  const showParent = process.process_level !== "main";
  const dropdownOptionsParents = [{ label: null, value: null, id: null }]
    .concat(
      filteredProcessesForParent?.map((process) => ({
        label: process.name,
        value: process.frontend_id,
        id: humanId(process.sequential_id, process.process_level),
      }))
    )
    .filter(Boolean);

  const dropdownOptionsPredecessors = [{ label: null, value: null, id: null }]
    .concat(
      updatePredecessors(processes, process)
        .sort(compareByProcessName)
        .map((process) => ({
          label: process.name,
          value: process.frontend_id,
          id: humanId(process.sequential_id, process.process_level),
        }))
    )
    .filter(Boolean);
  const isProcessPersisted = !!process?.id;

  return (
    <div
      id="processShow"
      className="flex-col flex-auto"
      style={{ maxHeight: "100%" }}
    >
      <React.Fragment>
        <div
          className="p-3 flex-wrap-reverse no-print"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div style={{ flex: "1 1 auto" }}>
            <Breadcrumbs />
          </div>

          <div
            style={{
              display: "flex",
              alignSelf: "end",
              alignItems: "center",
              columnGap: "18px",
            }}
          >
            <ToggleButton
              splitDirection={splitDirection}
              setSplitDirection={setSplitDirection}
              disabled={!isProcessPersisted}
            />

            <div>
              <Button
                type="text"
                icon={
                  <PrinterOutlined
                    style={{
                      fontSize: "16px",
                      position: "relative",
                      top: "-3px",
                    }}
                  />
                }
                style={{
                  color: "#156BB6",
                  fontSize: "14px",
                  fontWeight: 500,
                  textTransform: "uppercase",
                }}
                onClick={window.print}
                disabled={!isProcessPersisted}
              >
                {translations["processes.print"]}
              </Button>
            </div>

            {!readOnly && (
              <Dropdown
                disabled={!isProcessPersisted}
                overlay={
                  <Menu>
                    <Popconfirm
                      title={translations["processes.delete.confirm"]}
                      onConfirm={() => {
                        dispatch({
                          type: "process_delete",
                          payload: {
                            enterpriseId: enterprise.token,
                            processFrontendId: process.frontend_id,
                            navigate: () => navigate("/"),
                          },
                        });
                      }}
                      okText={translations["yes"]}
                      cancelText={translations["no"]}
                    >
                      <Menu.Item key="delete">
                        {translations["processes.delete.action"]}
                      </Menu.Item>
                    </Popconfirm>
                  </Menu>
                }
              >
                <Button
                  type="text"
                  icon={<EllipsisOutlined style={{ fontSize: "28px" }} />}
                  style={{ color: "#156BB6" }}
                  title={translations["buttons.more"]}
                />
              </Dropdown>
            )}
          </div>
        </div>
        <div
          className="content-container"
          style={{ flex: "1", height: "calc(100% - 64px)", overflow: "auto" }}
        >
          <div className="overflow-container">
            <div className="px-3 no-print">
              <div>
                <div className="flex flex-wrap gap-7 pb-3">
                  <div style={{ flexGrow: 12 }}>
                    <ProcessAttribute
                      key={`${process.frontend_id}-name`}
                      value={process.name}
                      name="name"
                      objectid={humanId(
                        process.sequential_id,
                        process.process_level
                      )}
                      label={
                        translations["activerecord.attributes.process.name"]
                      }
                      placeholder={
                        translations["helpers.placeholder.process.name"]
                      }
                      tooltip={translations["hints.process.name"]}
                      header={true}
                      onChange={handleChange}
                      readOnly={readOnly}
                    />
                  </div>

                  <div style={{ flexGrow: 1 }}>
                    <ProcessAttribute
                      key={`${process.frontend_id}-process_level`}
                      value={process.process_level}
                      name="process_level"
                      defaultValue="normal"
                      label={
                        translations[
                          "activerecord.attributes.process.process_level"
                        ]
                      }
                      tooltip={translations["hints.process.process_level"]}
                      radio={true}
                      radioOptions={dropdownOptionsProcessLevel}
                      onChange={handleChange}
                      disabled={!isProcessPersisted}
                      readOnly={readOnly}
                    />
                  </div>

                  <div style={{ flexGrow: 5 }}>
                    <ProcessAttribute
                      key={`${process.frontend_id}-process_owner`}
                      value={process.process_owner}
                      name="process_owner"
                      label={
                        translations[
                          "activerecord.attributes.process.process_owner"
                        ]
                      }
                      tooltip={translations["hints.process.process_owner"]}
                      onChange={handleChange}
                      autoCompleteOptions={getProcessAutoCompleteOptions(
                        "process_owner",
                        processes
                      )}
                      disabled={!isProcessPersisted}
                      readOnly={readOnly}
                    />
                  </div>
                </div>
                <div className="grid lg:grid-cols-2 md:grid-cols-2 pb-3">
                  {showParent && (
                    <ProcessAttribute
                      key={`${process.frontend_id}-parent`}
                      value={process.parent_frontend_id}
                      name="parent_frontend_id"
                      label={
                        translations[
                          "activerecord.attributes.process.parent_frontend_id"
                        ]
                      }
                      tooltip={translations["hints.process.parent_id"]}
                      placeholder={
                        translations[
                          "helpers.placeholder.process.parent_frontend_id"
                        ]
                      }
                      dropdown={true}
                      dropdownOptions={dropdownOptionsParents}
                      onChange={handleChange}
                      disabled={!isProcessPersisted}
                      readOnly={readOnly}
                    />
                  )}
                  <ProcessAttribute
                    key={`${process.frontend_id}-predecessor`}
                    value={process.predecessor_frontend_id}
                    name="predecessor_frontend_id"
                    label={
                      translations[
                        "activerecord.attributes.process.predecessor_frontend_id"
                      ]
                    }
                    tooltip={translations["hints.process.predecessor_id"]}
                    placeholder={
                      translations[
                        "helpers.placeholder.process.predecessor_frontend_id"
                      ]
                    }
                    dropdown={true}
                    dropdownOptions={dropdownOptionsPredecessors}
                    onChange={handleChange}
                    disabled={!isProcessPersisted}
                    readOnly={readOnly}
                  />
                </div>
                {isFullVersion && (
                  <div className="grid lg:grid-cols-4 md:grid-cols-2 pb-5">
                    <ProcessAttribute
                      key={`${process.frontend_id}-organisation_unit`}
                      value={process.organisation_unit}
                      name="organisation_unit"
                      label={
                        translations[
                          "activerecord.attributes.process.organisation_unit"
                        ]
                      }
                      tooltip={translations["hints.process.organisation_unit"]}
                      onChange={handleChange}
                      autoCompleteOptions={getProcessAutoCompleteOptions(
                        "organisation_unit",
                        processes
                      )}
                      disabled={!isProcessPersisted}
                      readOnly={readOnly}
                    />

                    <ProcessAttribute
                      key={`${process.frontend_id}-process_type`}
                      value={process.process_type}
                      name="process_type"
                      label={
                        translations[
                          "activerecord.attributes.process.process_type"
                        ]
                      }
                      tooltip={translations["hints.process.process_type"]}
                      onChange={handleChange}
                      autoCompleteOptions={getProcessAutoCompleteOptions(
                        "process_type",
                        processes
                      )}
                      disabled={!isProcessPersisted}
                      readOnly={readOnly}
                    />

                    <ProcessAttribute
                      key={`${process.frontend_id}-status`}
                      value={process.status}
                      name="status"
                      label={
                        translations["activerecord.attributes.process.status"]
                      }
                      tooltip={translations["hints.process.status"]}
                      onChange={handleChange}
                      autoCompleteOptions={getProcessAutoCompleteOptions(
                        "status",
                        processes
                      )}
                      disabled={!isProcessPersisted}
                      readOnly={readOnly}
                    />
                  </div>
                )}
                {isFullVersion && (
                  <div className="grid lg:grid-cols-4 md:grid-cols-2">
                    <ProcessAttribute
                      key={`${process.frontend_id}-description`}
                      className="lg:col-span-4 md:col-span-2 col-span-1"
                      value={process.description}
                      name="description"
                      label={
                        translations[
                          "activerecord.attributes.process.description"
                        ]
                      }
                      tooltip={translations["hints.process.description"]}
                      onChange={handleChange}
                      disabled={!isProcessPersisted}
                      readOnly={readOnly}
                    />
                  </div>
                )}
              </div>
            </div>

            <SplitWrapper splitDirection={splitDirection}>
              <div className="p-3 pt-0 no-print">
                {isProcessPersisted && (
                  <EventTable
                    key={`${process.frontend_id}-events`}
                    sipocElements={sipocElements}
                    enterpriseId={enterprise.token ?? enterprise.read_token}
                    processFrontendId={process.frontend_id}
                    process={process}
                    readOnly={readOnly}
                  />
                )}
                {isProcessPersisted && (
                  <SipocDataTable
                    key={`${process.frontend_id}-siocs`}
                    sipocElements={sipocElements}
                    enterpriseId={enterprise.token ?? enterprise.read_token}
                    processFrontendId={process.frontend_id}
                    readOnly={readOnly}
                  />
                )}
              </div>

              <div style={{ flex: "1 1 auto" }} className="p-3">
                <ProcessLabel label={translations["processes.sipoc_diagram"]} />
                {isProcessPersisted && sipocElements && (
                  <Sipoc
                    processes={processesWithElements}
                    firstPaneSize={firstPaneSize}
                  />
                )}
              </div>
            </SplitWrapper>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};

const mapStateToProps = ({ user, processes }) => ({ user, processes });

const RenderProcessWithProcesses = connect(mapStateToProps)(RenderProcess);

export default withTranslations(ProcessShow);
