import * as React from "react";
import { Enterprise, Translations } from "../../helper/types";
import withTranslations from "../../helper/withTranslations";
import { Button, Input, Popover } from "antd";
import { CopyOutlined, ShareAltOutlined } from "@ant-design/icons";

type Props = {
  enterprise?: Enterprise;
  translations: Translations;
};

const ShareButtonContent = ({
  enterprise,
  translations,
}: {
  enterprise: Enterprise;
  translations: Translations;
}) => (
  <div className="flex flex-col gap-4">
    <CopyUrl
      url={enterprise.editing_url}
      translations={translations}
      label={translations["navigation.share.edit_label"]}
    />
    <CopyUrl
      url={enterprise.viewing_url}
      translations={translations}
      label={translations["navigation.share.view_label"]}
    />
  </div>
);

const CopyUrl = ({
  url,
  translations,
  label,
}: {
  url: string;
  translations: Translations;
  label: string;
}) => (
  <div className="flex flex-col gap-2">
    <div className="font-bold">{label}</div>
    <div className="flex gap-2">
      <Input value={url} contentEditable={false} />
      <Button
        icon={<CopyOutlined />}
        onClick={() => navigator.clipboard.writeText(url)}
        title={translations["navigation.share.copy_to_clipboard"]}
      />
    </div>
  </div>
);

const ShareButton = ({ enterprise, translations }: Props) => {
  if (!enterprise) return null;

  return (
    <Popover
      content={
        <ShareButtonContent
          enterprise={enterprise}
          translations={translations}
        />
      }
      trigger="click"
    >
      <Button className="bg-white rounded flex items-center uppercase hover:bg-white cursor-pointer px-4 py-2 gap-3">
        <ShareAltOutlined />
        {translations["navigation.share.button"]}
      </Button>
    </Popover>
  );
};

export default withTranslations(ShareButton);
