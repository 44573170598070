import * as React from "react";

// export default () => <svg fill="currentColor">
//     <path fill="#ffffff" fillOpacity="0" stroke="currentColor" stroke-width="3" d="M 0,0 0,63 c 0,0 38,9 61,8 C 110.79252336448597,75 143.73084112149533,43.5 213.6,45 L 213.6,0 z"></path>
// </svg>
//export default () => <svg fill="currentColor"><path fill="currentColor" fillOpacity="0" stroke="currentColor" stroke-width="3" d="M 0,0 0,63 c 0,0 38,9 61,8 C 110.79252336448597,75 143.73084112149533,43.5 213.6,45 L 213.6,0 z"/></svg>

// export default () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 -3 219.6 77">
// <path d="M 0,0 0,63 c 0,0 38,9 61,8 C 110.79252336448597,75 143.73084112149533,43.5 213.6,45 L 213.6,0 z" stroke="#FF0000" stroke-width="3" fill="#000000"/>
// </svg>

// M0 0v63s38 9 61 8c49.793 4 82.73-27.5 152.6-26V0z

export const SplitVertical = () => (
  <svg
    fill="currentColor"
    width="1em"
    height="1em"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="137.53 67.52 424.96 424.97"
  >
    {" "}
    <path d="m161.14 67.52c-6.2617-0.003906-12.27 2.4844-16.699 6.9141-4.4297 4.4297-6.918 10.438-6.9141 16.699v377.76c0.003906 6.2578 2.4961 12.262 6.9219 16.684 4.4297 4.4258 10.434 6.9102 16.691 6.9102h377.73c6.2578 0 12.262-2.4844 16.691-6.9102 4.4258-4.4219 6.918-10.426 6.9219-16.684v-377.76c0.003907-6.2617-2.4844-12.27-6.9141-16.699-4.4297-4.4297-10.438-6.918-16.699-6.9141zm23.598 47.23h153.47v330.53l-153.47-0.003906zm177.07 0h153.47v330.53h-153.47z"></path>{" "}
  </svg>
);

export const SplitHorizontal = () => (
  <svg
    fill="currentColor"
    width="1em"
    height="1em"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="137.53 67.52 424.96 424.97"
  >
    {" "}
    <path d="m161.14 67.52c-6.2578-0.003906-12.262 2.4844-16.691 6.9062-4.4258 4.4258-6.918 10.426-6.9219 16.684v377.76c-0.003907 6.2617 2.4844 12.27 6.9141 16.699 4.4297 4.4297 10.438 6.918 16.699 6.918h377.73c6.2617 0 12.27-2.4883 16.699-6.918 4.4297-4.4297 6.918-10.438 6.9141-16.699v-377.76c-0.003906-6.2578-2.4961-12.258-6.9219-16.684-4.4297-4.4219-10.434-6.9102-16.691-6.9062zm23.598 47.207h330.53v153.46l-330.53-0.003906zm0 177.07h330.53v153.46h-330.53z"></path>{" "}
  </svg>
);
