import * as React from "react";
import withTranslations from "../helper/withTranslations";
import ProcessShow from "./ProcessShow";
import { v4 as uuid } from "uuid";
import { connect } from "react-redux";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";

const ProcessNew = ({ enterprise, processes }) => {
  const [frontendId] = React.useState(uuid());
  const navigate = useNavigate();

  const process = processes?.find(
    (process) => process.frontend_id === frontendId
  );

  React.useEffect(() => {
    navigate(`/processes/${frontendId}`, { replace: true });
  }, [process]);

  if (!processes) {
    return <Spin size="large" />;
  }

  return (
    <ProcessShow
      enterprise={enterprise}
      process={process ?? { frontend_id: frontendId }}
      sipocElements={{}}
      readOnly={false}
    />
  );
};

const mapStateToProps = ({ enterprises, processes }) => ({
  enterprise: enterprises.current,
  processes,
});

export default withTranslations(connect(mapStateToProps)(ProcessNew));
