import React from "react";
import { Menu, Dropdown, Tag, AutoComplete, Tooltip, Popconfirm } from "antd";
import { PlusOutlined, EllipsisOutlined } from "@ant-design/icons";
import Icon from "@ant-design/icons";
import Document from "./Document";
import Data from "./Data";
import ValueChain from "./ValueChain";
import { debounce } from "lodash";
import withTranslations from "../../helper/withTranslations";
import InlineEdit from "../ProcessAttribute/InlineEdit";

const getDatatypeIcon = (datatype) => {
  switch (datatype) {
    case "document":
      return Document;
    case "data":
      return Data;
    case "value_chain":
      return ValueChain;
  }
};

interface Props {
  value?: string;
  disabled?: boolean;
  description?: string;
  id?: string;
  datatype?: string;
  type?: string;
  readOnly?: boolean;
}

const handleMenuClick =
  (dispatch, type, setShowDescription, frontendId, setDisabled) => (e: any) => {
    switch (e.key) {
      case "description":
        setShowDescription(true);
        break;
      case "delete":
        if (type.startsWith("Event")) {
          dispatch("event_delete", {
            event: { eventType: type },
          });
          setDisabled(true);
        } else {
          dispatch("sipocelement_delete", {
            sipocElementId: frontendId,
          });
        }
        break;
    }
  };

const handleDataTypeMenuClick =
  (dispatch, type, frontendId, sequenceFrontendId) => (e: any) => {
    const newDatatype = e.key;

    dispatch("sipocelement_update", {
      sipocElement: {
        document_type: newDatatype,
        type,
        frontend_id: frontendId,
        sequence_frontend_id: sequenceFrontendId,
      },
    });
  };

const handleChange = (name, dispatch, type, frontendId, sequenceFrontendId) =>
  debounce((value) => {
    if (type.startsWith("Event")) {
      dispatch("event_update", {
        event: { [name]: value, event_type: type },
      });
    } else {
      dispatch("sipocelement_update", {
        sipocElement: {
          [name]: value,
          frontend_id: frontendId,
          type,
          sequence_frontend_id: sequenceFrontendId,
        },
      });
    }
  }, 1000);

const menu = (
  dispatch,
  type,
  showDescription,
  setShowDescription,
  translations,
  frontendId,
  setDisabled
) => (
  <Menu
    onClick={handleMenuClick(
      dispatch,
      type,
      setShowDescription,
      frontendId,
      setDisabled
    )}
  >
    {!showDescription && (
      <Menu.Item key="description">
        {translations["elements.add_description"]}
      </Menu.Item>
    )}
    {frontendId && (
      <Popconfirm
        key={`${frontendId}-confirmDelete`}
        title={translations["sipoc_elements.delete.confirm"]}
        onConfirm={() =>
          handleMenuClick(
            dispatch,
            type,
            setShowDescription,
            frontendId,
            setDisabled
          )({ key: "delete" })
        }
        okText={translations["yes"]}
        cancelText={translations["no"]}
      >
        <Menu.Item key={`${frontendId}-itemDelete`}>
          {translations["elements.delete"]}
        </Menu.Item>
      </Popconfirm>
    )}
  </Menu>
);

const datatypeMenu = (
  dispatch,
  type,
  frontendId,
  translations,
  sequenceFrontendId
) => (
  <Menu
    onClick={handleDataTypeMenuClick(
      dispatch,
      type,
      frontendId,
      sequenceFrontendId
    )}
  >
    <Menu.Item key="document">
      <Icon
        component={Document}
        style={{
          fontSize: "28px",
          color: "#000",
          transform: "scaleX(0.5)",
        }}
      />
      {translations["enumerize.sipoc_element.document_type.document"]}
    </Menu.Item>
    <Menu.Item key="data">
      <Icon
        component={Data}
        style={{
          fontSize: "28px",
          color: "#000",
          transform: "scaleX(0.5)",
        }}
      />
      {translations["enumerize.sipoc_element.document_type.data"]}
    </Menu.Item>
    <Menu.Item key="value_chain">
      <Icon
        component={ValueChain}
        style={{
          fontSize: "28px",
          color: "#000",
          transform: "scaleX(0.5)",
        }}
      />
      {translations["enumerize.sipoc_element.document_type.value_chain"]}
    </Menu.Item>
  </Menu>
);

const ElementInput: React.FC<Props> = ({
  value = undefined,
  type,
  disabled: initialDisabled = false,
  description = undefined,
  id = undefined,
  datatype = undefined,
  dispatch,
  translations,
  autocompleteOptions,
  frontendId,
  sequenceFrontendId,
  readOnly = false,
}) => {
  const [showDescription, setShowDescription] = React.useState(
    description && description.trim().length > 0
  );
  const [disabled, setDisabled] = React.useState(initialDisabled);

  return (
    <div
      className={
        disabled
          ? "element-input-disabled"
          : "element-input rounded drop-shadow-sm"
      }
      title={disabled ? `${translations["sipoc_elements.add"]} ${type}` : null}
    >
      {!disabled && (
        <React.Fragment>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              className="font-medium text-base"
              style={{ flex: "1 1 auto", minWidth: "160px" }}
            >
              <AutoComplete
                options={autocompleteOptions}
                defaultValue={value}
                onChange={handleChange(
                  "name",
                  dispatch,
                  type,
                  frontendId,
                  sequenceFrontendId
                )}
                style={{ width: "100%" }}
                className="editableText"
                data-name={type}
                getPopupContainer={(triggerNode: HTMLElement) =>
                  triggerNode.parentNode as HTMLElement
                }
                disabled={readOnly}
              />
            </div>
            {id && (
              <div>
                <Tag className="tag-id" style={{ marginRight: "3px" }}>
                  {id}
                </Tag>
              </div>
            )}
            {datatype && (
              <Tooltip
                title={
                  translations[
                    `enumerize.sipoc_element.document_type.${datatype}`
                  ]
                }
              >
                <Dropdown
                  overlay={datatypeMenu(
                    dispatch,
                    type,
                    frontendId,
                    translations,
                    sequenceFrontendId
                  )}
                  disabled={readOnly}
                >
                  <Icon
                    component={getDatatypeIcon(datatype)}
                    style={{
                      fontSize: "28px",
                      color: "#000",
                      transform: "scaleX(0.5)",
                    }}
                  />
                </Dropdown>
              </Tooltip>
            )}
            {!readOnly && (
              <div style={{ marginLeft: "1px" }}>
                <Dropdown
                  overlay={menu(
                    dispatch,
                    type,
                    showDescription,
                    setShowDescription,
                    translations,
                    frontendId,
                    setDisabled
                  )}
                >
                  <EllipsisOutlined
                    style={{ fontSize: "24px", color: "#000" }}
                  />
                </Dropdown>
              </div>
            )}
          </div>
          {showDescription && (
            <div>
              <InlineEdit
                name="description"
                defaultValue={description}
                onBlur={(value) =>
                  handleChange(
                    "description",
                    dispatch,
                    type,
                    frontendId,
                    sequenceFrontendId
                  )(value)
                }
                className="editableText text-sm"
                multiline={true}
                disabled={readOnly}
              />
            </div>
          )}
        </React.Fragment>
      )}
      {disabled && !readOnly && (
        <div
          className="flex items-center justify-center cursor-pointer"
          onClick={() => setDisabled(false)}
        >
          <div className="flex items-center justify-items-center gap-2">
            <PlusOutlined className="text-gray-600" />
            <div className="uppercase text-gray-600">
              {translations["sipoc_elements.add"]}{" "}
              {translations[`types.${type}`]}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withTranslations(ElementInput);
