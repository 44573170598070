import * as React from "react";
import { useState } from "react";
import { MenuOutlined, LoadingOutlined } from "@ant-design/icons";
import Logo from "images/processhorizon_white.svg";
import withTranslations from "../helper/withTranslations";
import { Button, Spin, Alert, Drawer } from "antd";
import { connect } from "react-redux";
import Navigation from "./Navigation/Navigation";
import GuestNavigation from "./Navigation/GuestNavigation";
import Sidebar from "./Sidebar";
import NewProcessButton from "./Navigation/NewProcessButton";
import AccountDropDown from "./Navigation/AccountDropDown";
import ShareButton from "./Navigation/ShareButton";
import CloneButton from "./Navigation/CloneButton";

const Header = ({
  enterprises,
  user,
  translations,
  syncStatus,
  error,
  readOnly,
}) => {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  return (
    <div className="no-print">
      <Drawer
        closable={false}
        placement="left"
        onClose={onClose}
        open={visible}
        bodyStyle={{ padding: "0px" }}
        width="80vw"
      >
        <Sidebar setVisible={setVisible} readOnly={readOnly} />
      </Drawer>
      <div
        style={{
          backgroundColor: "#156BB6",
          height: "56px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="p-2 flex md:hidden">
          <Button
            type="text"
            icon={<MenuOutlined style={{ fontSize: "20px" }} />}
            style={{ color: "#fff" }}
            onClick={showDrawer}
          />
        </div>
        <div className="p-2 md:w-full logo-maxwidth">
          <img src={Logo} width="180" style={{ marginTop: "6px" }} />
        </div>
        <div
          className="pl-4 hidden md:flex justify-between"
          style={{
            flex: "1 1 auto",
            columnGap: 10,
            alignItems: "center",
            height: "56px",
          }}
        >
          <div className="flex flex-wrap items-center">
            {!readOnly && <NewProcessButton />}
            {enterprises?.current && (
              <Navigation inSidebar={false} isLoggedIn={user.logged_in} />
            )}
            {!readOnly && (
              <div className="ml-10">
                <ShareButton enterprise={enterprises?.current} />
              </div>
            )}
            {readOnly && (
              <div className="ml-10">
                <CloneButton enterprise={enterprises?.current} />
              </div>
            )}
          </div>
          <div className="flex flex-wrap items-center">
            {!user.logged_in && <GuestNavigation inSidebar={false} />}
          </div>
        </div>
        {error && (
          <Alert message={translations["errors.data"]} type="error" showIcon />
        )}
        <div>
          {syncStatus && syncStatus.length > 0 && (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 24, color: "white" }}
                  spin
                />
              }
            />
          )}
        </div>
        <div className="hidden lg:flex" style={{ alignItems: "center" }}>
          <AccountDropDown />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ enterprises, user, syncStatus, error }) => ({
  enterprises,
  user,
  syncStatus,
  error,
});

export default connect(mapStateToProps)(withTranslations(Header));
