import { Node } from "@antv/x6";
import { Graph } from "@antv/x6";

class DocumentCurvy extends Node {}

DocumentCurvy.config({
  width: 100,
  height: 40,
  markup: [
    {
      tagName: "path",
      selector: "body",
    },
    {
      tagName: "text",
      selector: "label",
    },
    {
      tagName: "text",
      selector: "human_id",
    },
  ],
  attrs: {
    body: {
      rx: 10,
      ry: 10,
      fill: "#ffffff",
      stroke: "#3465a4",
      strokeWidth: 3,
    },
    label: {
      fontSize: 14,
      fill: "black",
      textAnchor: "middle",
      textVerticalAnchor: "middle",
      textWrap: {
        width: -10,
      },
    },
    human_id: {
      fontSize: 10,
      fill: "black",
      textAnchor: "end",
      textVerticalAnchor: "middle",
    },
  },

  propHooks(metadata) {
    const { label, human_id, size } = metadata;
    const humanIdY = 10;

    if (label) {
      metadata.attrs = {};
      metadata.attrs.label = {};
      metadata.attrs.label.textWrap = {};
      metadata.attrs.label.textWrap.text = label;
      metadata.attrs.label.refX = size.width / 2;
      metadata.attrs.label.refY = size.height / 2 + humanIdY - 2;
      metadata.attrs.body = {};
      metadata.attrs.body.d = `M 0,0 0,${
        (size.height * 45) / 50
      } c 0,0 38,9 61,8 C ${(size.width * 111) / 214},${size.height} ${
        (size.width * 144) / 214
      },${(size.height * 40) / 50} ${size.width},${(size.height * 40) / 50} L ${
        size.width
      },0 z`;
    }

    if (human_id) {
      metadata.attrs.human_id = {};
      metadata.attrs.human_id.text = human_id;
      metadata.attrs.human_id.dx = size.width;
      metadata.attrs.human_id.width = size.width;
      metadata.attrs.human_id.refX = -4;
      metadata.attrs.human_id.refY = humanIdY;
    }

    return metadata;
  },
});

Graph.registerNode("DocumentCurvy", DocumentCurvy);
