import * as React from "react";
import withTranslations from "../helper/withTranslations";
import { connect, useDispatch } from "react-redux";
import ProcessLabel from "./ProcessLabel/ProcessLabel";
import ElementInput from "./ElementInput/ElementInput";
import humanId from "../helper/human_id";

const EventTable = ({
  translations,
  enterpriseId,
  processFrontendId,
  process,
  eventsAutocomplete,
  readOnly,
}) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!readOnly) {
      dispatch({
        type: "events_autocomplete_fetch",
        payload: {
          enterpriseId,
          processLevel: process.process_level,
        },
      });
    }
  }, [process, readOnly]);

  const dispatchElement =
    (enterpriseId, processFrontendId) => (action_type, payload) => {
      dispatch({
        type: action_type,
        payload: {
          enterpriseId,
          processFrontendId,
          ...payload,
        },
      });
    };

  return (
    <div className="flex flex-wrap gap-4">
      <div className="element-table event-table">
        <div className="mb-1">
          <ProcessLabel
            label={translations["activerecord.models.event_trigger.one"]}
            tooltip={translations["hints.event_trigger"]}
          />
        </div>

        <div>
          <ElementInput
            value={process.trigger?.name}
            description={process.trigger?.description}
            id={humanId(process.trigger?.sequential_id, "EventTrigger")}
            type="EventTrigger"
            dispatch={dispatchElement(enterpriseId, processFrontendId)}
            autocompleteOptions={eventsAutocomplete}
            frontendId={process.trigger?.id}
            disabled={!process.trigger?.id}
            readOnly={readOnly}
          />
        </div>
      </div>

      <div className="element-table event-table">
        <div className="mb-1">
          <ProcessLabel
            label={translations["activerecord.models.event_completion.one"]}
            tooltip={translations["hints.event_completion"]}
          />
        </div>

        <div>
          <ElementInput
            value={process.completion?.name}
            description={process.completion?.description}
            id={humanId(process.completion?.sequential_id, "EventCompletion")}
            type="EventCompletion"
            dispatch={dispatchElement(enterpriseId, processFrontendId)}
            autocompleteOptions={eventsAutocomplete}
            frontendId={process.completion?.id}
            disabled={!process.completion?.id}
            readOnly={readOnly}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ eventsAutocomplete }) => ({ eventsAutocomplete });

export default connect(mapStateToProps)(withTranslations(EventTable));
