import { sortBy } from "lodash";
import {SIOCAutoComplete, SIOCKey} from "./types";

export type AntAutoComplete = {
  value: string;
};

export const onlyUnique = (
  element: AntAutoComplete,
  index: number,
  self: AntAutoComplete[]
) => self.findIndex((e) => e.value === element.value) === index;

export const prepareAutocomplete = (
  elements: SIOCAutoComplete[],
  types: SIOCKey[]
): AntAutoComplete[] =>
  sortBy(
    elements
      .filter((e) => types.includes(e.type))
      .map((element) => ({ value: element.name }))
      .filter((e) => e.value)
      .filter(onlyUnique),
    (element: AntAutoComplete) => element.value.toLowerCase()
  );

export const ioAutocomplete = (elements: SIOCAutoComplete[]) =>
  prepareAutocomplete(elements, ["Input", "Output"]);

export const scAutocomplete = (elements: SIOCAutoComplete[]) =>
  prepareAutocomplete(elements, ["Supplier", "Customer"]);
