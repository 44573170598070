import * as React from "react";
import { createSelector } from "@reduxjs/toolkit";
import { Empty, Spin, Grid } from "antd";
import { connect, useDispatch } from "react-redux";
import { Routes, Route, useParams, Navigate } from "react-router-dom";

import Const from "../constants/frontendConstants";
import ProcessNew from "../components/ProcessNew";
import ProcessShow from "../components/ProcessShow";
import LinkButton from "./LinkButton";
import ProcessMap from "../components/ProcessMap";
import withTranslations from "../helper/withTranslations";
import ErrorBoundary from "./ErrorBoundary";
import ReportsRouter from "./ReportsRouter";
import Sidebar from "./Sidebar";
import BreakpointWrapper from "./BreakpointWrapper";
import Split from "react-split";
import Header from "./Header";

const { useBreakpoint } = Grid;

const Home = withTranslations(({ translations, processes, readOnly }) => {
  if (!processes) {
    return <Spin size="large" />;
  }

  if (processes.length > 0) {
    return <RedirectTo to={`/processes/${processes[0].frontend_id}`} />;
  } else {
    return (
      <Empty description={translations["processes.empty"]} className="mt-10">
        {!readOnly && (
          <LinkButton to={"/processes/new"} type="primary">
            {translations["processes.new.action"]}
          </LinkButton>
        )}
      </Empty>
    );
  }
});

const ProcessShowFetch2 = React.memo(
  ({ enterprises, processes, sipocElements, readOnly }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const enterprise = enterprises.current;

    if (!id) return null;
    const processFrontendId = id;

    React.useEffect(() => {
      if (processes) {
        const process = processes.find((p) => p.frontend_id === id);

        // Don't request elements when the process has not yet been stored in the database
        if (process?.id) {
          dispatch({
            type: "sipocelements_fetch",
            payload: {
              enterpriseId: enterprise.token ?? enterprise.read_token,
              processFrontendId: processFrontendId,
              readOnly,
            },
          });
        }
      }
    }, [id, processes]);

    if (processes && processFrontendId) {
      const currentProcess = processes.find(
        (process) => process.frontend_id === processFrontendId
      );

      return (
        <ProcessShow
          enterprise={enterprise}
          process={currentProcess ?? { frontend_id: processFrontendId }}
          sipocElements={sipocElements}
          readOnly={readOnly}
        />
      );
    } else {
      return null;
    }
  }
);

const selectProcess = createSelector(
  [
    (state) => state.enterprises,
    (state) => state.processes,
    (state) => state.sipocElements,
  ],
  (enterprises, processes, sipocElements) => ({
    enterprises,
    processes,
    sipocElements,
  })
);

const ProcessShowFetch = connect(selectProcess)(ProcessShowFetch2);

const RedirectTo = ({ to }) => <Navigate to={to} replace={true} />;

const Frontend = ({ enterprises, processes, readOnly }) => {
  const dispatch = useDispatch();
  const breakpoints = useBreakpoint();

  React.useEffect(() => {
    if (!processes) {
      dispatch({
        type: "processes_fetch",
        payload: {
          enterpriseId: readOnly
            ? enterprises.current.read_token
            : enterprises.current.token,
          readOnly,
        },
      });
    }
  }, [processes]);

  return (
    <ErrorBoundary>
      <main
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Header readOnly={readOnly} />
        <div className="split" style={{ overflow: "hidden" }}>
          <BreakpointWrapper
            condition={breakpoints.md}
            wrapper={(
              children:
                | React.ReactChild
                | React.ReactFragment
                | React.ReactPortal
                | null
                | undefined
            ) => (
              <Split
                sizes={[20, 80]}
                minSize={100}
                expandToMin={false}
                gutterSize={1}
                gutterAlign="center"
                snapOffset={30}
                dragInterval={1}
                direction="horizontal"
                cursor="col-resize"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: "1 1 auto",
                  overflow: "hidden",
                }}
              >
                {children}
              </Split>
            )}
          >
            {breakpoints.md && <Sidebar readOnly={readOnly} />}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                //overflowY: "auto",
                //overflowX: "hidden",
                flex: "1 1 auto",
              }}
            >
              <Routes>
                {processes && processes.length > 0 && (
                  <Route
                    path="/processes"
                    element={
                      <RedirectTo
                        to={`/processes/${processes[0].frontend_id}`}
                      />
                    }
                  />
                )}

                <Route
                  path="/"
                  element={<Home processes={processes} readOnly={readOnly} />}
                />
                <Route
                  path="/frontend"
                  element={<Home processes={processes} readOnly={readOnly} />}
                />

                <Route path="/processes/new" element={<ProcessNew />} />
                <Route
                  path="/processes/:id"
                  element={<ProcessShowFetch readOnly={readOnly} />}
                />
                <Route
                  path="/processmap"
                  element={
                    <ProcessMap
                      enterprise={enterprises.current}
                      processes={processes}
                    />
                  }
                />
                <Route
                  path="/processmap/:id"
                  element={
                    <ProcessMap
                      enterprise={enterprises.current}
                      processes={processes}
                    />
                  }
                />
                <Route path="/reports/*" element={<ReportsRouter />} />
              </Routes>
            </div>
          </BreakpointWrapper>
        </div>
        <div className="only-print print-footer">
          powered by Process Horizon&trade;,{" "}
          {new Date().toLocaleDateString(undefined, {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })}
        </div>
      </main>
    </ErrorBoundary>
  );
};

export default Frontend;
