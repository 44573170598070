import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import configureStore from "../store/frontendStore";
import FrontendContainer from "../containers/FrontendContainer";

const FrontendApp = (railsProps) => {
  const store = configureStore(railsProps);

  return (
    <Provider store={store}>
      <BrowserRouter
        basename={`/enterprises/${
          store.getState().enterprises.current.token ||
          store.getState().enterprises.current.read_token
        }/frontend`}
      >
        <FrontendContainer
          readOnly={!store.getState().enterprises.current.token}
        />
      </BrowserRouter>
    </Provider>
  );
};

export default FrontendApp;
