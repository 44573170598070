import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import * as React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import withTranslations from "../../helper/withTranslations";

const NewProcessButton = ({
  enterprises,
  translations,
  size,
  className,
  setVisible,
}) => {
  const navigate = useNavigate();
  const navigateToProcessNew = () => {
    navigate("/processes/new");

    if (setVisible) setVisible(false);
  };

  return (
    <>
      {enterprises && enterprises.current && (
        <Button
          onClick={navigateToProcessNew}
          type="primary"
          icon={
            <PlusOutlined
              style={{
                fontSize: "16px",
                position: "relative",
                top: "-3px",
              }}
            />
          }
          style={{
            color: "#fff",
            fontSize: "14px",
            fontWeight: 500,
            backgroundColor: "#1890ff",
            textTransform: "uppercase",
          }}
          className={className}
          size={size}
        >
          {translations["processes.new.action"]}
        </Button>
      )}
    </>
  );
};

const mapStateToProps = ({ enterprises }) => ({ enterprises });

export default withTranslations(connect(mapStateToProps)(NewProcessButton));
