import { Node } from "@antv/x6";
import { Graph } from "@antv/x6";

const fontSize = 10;

class Event extends Node {}

Event.config({
  width: 100,
  height: 40,
  markup: [
    {
      tagName: "rect",
      selector: "body",
    },
    {
      tagName: "text",
      selector: "label",
    },
    {
      tagName: "text",
      selector: "human_id",
    },
  ],
  attrs: {
    body: {
      rx: 40,
      ry: 40,
      fill: "#ffffff",
      stroke: "#3465a4",
      strokeWidth: 3,
    },
    label: {
      fontSize: 14,
      fill: "black",
      textAnchor: "middle",
      textVerticalAnchor: "middle",
      textWrap: {
        width: -10,
      },
    },
    human_id: {
      fontSize: fontSize,
      fill: "black",
      textAnchor: "end",
      textVerticalAnchor: "middle",
    },
  },

  propHooks(metadata) {
    const { label, human_id, size } = metadata;
    const humanIdY = fontSize;

    if (label) {
      metadata.attrs = {};
      metadata.attrs.label = {};
      metadata.attrs.label.textWrap = {};
      metadata.attrs.label.textWrap.text = label;
      metadata.attrs.label.refX = size.width / 2;
      metadata.attrs.label.refY = size.height / 2 + humanIdY - 2;
      metadata.attrs.body = {};
      metadata.attrs.body.width = size.width;
      metadata.attrs.body.height = size.height;
    }

    if (human_id) {
      metadata.attrs.human_id = {};
      metadata.attrs.human_id.text = human_id;
      metadata.attrs.human_id.dx = size.width;
      metadata.attrs.human_id.width = size.width;
      metadata.attrs.human_id.refX = -fontSize - 14;
      metadata.attrs.human_id.refY = fontSize;
    }

    return metadata;
  },
});

Graph.registerNode("Event", Event);
