import * as React from "react";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import "./ProcessLabel.css";

interface Props {
  label?: string;
  tooltip?: string;
}

const ProcessLabel: React.FC<Props> = ({
  label = undefined,
  tooltip = undefined,
}) => {
  return (
    <div className="process-label" style={{ display: "flex" }}>
      {tooltip && (
        <Tooltip placement="right" title={tooltip} className="tooltipoverride">
          <div>{label}</div>
          <InfoCircleOutlined style={{ fontSize: 12, marginLeft: "5px" }} />
        </Tooltip>
      )}
      {!tooltip && <div>{label}</div>}
    </div>
  );
};

export default ProcessLabel;
