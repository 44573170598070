import { connect } from "react-redux";
import Frontend from "../components/Frontend";

const mapStateToProps = ({ enterprises, processes, translations }) => ({
  enterprises,
  processes,
  translations,
});

export default connect(mapStateToProps)(Frontend);
