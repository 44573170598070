import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "antd";
import "./Navigation.css";
import withTranslations from "../../helper/withTranslations";
import { getCurrentProcessFrontendId } from "../../helper/location";
import { connect } from "react-redux";

interface Props {
  inSidebar: boolean;
  setVisible?: any;
}

const activeClass = "menu-active";
const inactiveClass = "menu-inactive";

const Navigation: React.FC<Props> = ({
  inSidebar = false,
  setVisible,
  translations,
  processes,
  isLoggedIn,
}) => {
  const location = useLocation();
  const sidebarClass = inSidebar ? "menu-sidebar" : "menu-header";
  const buttonProps: any = {};

  if (inSidebar) {
    buttonProps["onClick"] = () => inSidebar && setVisible(false);
    buttonProps["size"] = "large";
  }
  buttonProps["style"] = { textTransform: "uppercase" };

  const processFrontendId = getCurrentProcessFrontendId();
  const processesPath = (controller: "processes" | "processmap") => {
    if (processFrontendId) {
      return `/${controller}/${processFrontendId}`;
    } else {
      if (processes && processes.length > 0) {
        return `/${controller}/${processes[0].frontend_id}`;
      } else {
        // fix nav if there are no processes
        return controller === "processes" ? "/" : "/processmap";
      }
    }

    return "/${controller}";
  };

  let selectedMenuItem;
  if (location.pathname.startsWith("/processes") || location.pathname === "/")
    selectedMenuItem = "processes";
  if (location.pathname.startsWith("/processmap"))
    selectedMenuItem = "processmap";
  if (location.pathname.startsWith("/reports")) selectedMenuItem = "reports";

  return (
    <React.Fragment>
      <div
        className={`${
          selectedMenuItem === "processes" ? activeClass : inactiveClass
        } ${sidebarClass} ${!inSidebar && "ml-10"} `}
      >
        <Link to={processesPath("processes")}>
          <Button type="text" {...buttonProps}>
            {translations["navigation.processes"]}
          </Button>
        </Link>
      </div>
      <div
        className={`ml-5 ${
          selectedMenuItem === "processmap" ? activeClass : inactiveClass
        } ${sidebarClass}`}
      >
        <Link to={processesPath("processmap")}>
          <Button type="text" {...buttonProps}>
            {translations["navigation.processmap"]}
          </Button>
        </Link>
      </div>
      {isLoggedIn && (
        <div
          className={`ml-5 ${
            selectedMenuItem === "reports" ? activeClass : inactiveClass
          } ${sidebarClass}`}
        >
          <Link to="/reports">
            <Button type="text" {...buttonProps}>
              {translations["navigation.reports"]}
            </Button>
          </Link>
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({ processes }) => ({ processes });

export default withTranslations(connect(mapStateToProps)(Navigation));
