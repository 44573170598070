import * as React from "react";

const Input = React.forwardRef((props, ref) => {
  const { multiline, ...inputProps } = props;

  return multiline ? (
    <textarea {...inputProps} ref={ref} />
  ) : (
    <input {...inputProps} ref={ref} />
  );
});

type Props = {
  name: string;
  defaultValue: string | null;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  className?: string;
  autoComplete?: string;
  placeholder?: string;
  multiline?: boolean;
  disabled?: boolean;
};

const InlineEdit = ({
  name,
  defaultValue,
  onChange,
  onBlur,
  className,
  autoComplete,
  placeholder,
  multiline = false,
  disabled = false,
}: Props) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [value, setValue] = React.useState(defaultValue);
  const inputRef = React.useRef(null);

  return (
    <>
      <Input
        name={name}
        type="text"
        defaultValue={value}
        onBlur={() => {
          setIsEditing(false);
          if (onBlur) onBlur(value);
        }}
        onChange={(event) => {
          setValue(event.target.value);
          if (onChange) onChange(event.target.value);
        }}
        onKeyDown={(event) => {
          if (["Enter", "Return", "Tab"].includes(event.key)) {
            if (!multiline || event.getModifierState("Control")) {
              setIsEditing(false);
              if (onBlur) onBlur(value);
            }
          }
        }}
        autoFocus
        className={className}
        ref={inputRef}
        autoComplete={autoComplete}
        style={{
          display: isEditing ? "inherit" : "none",
          background: "transparent",
          fontSize: "inherit",
          width: "100%",
          resize: "none",
          color: disabled ? "#9ca3af" : "inherit",
        }}
        multiline={multiline}
        onInput={(event) => {
          if (!multiline) return;

          const textarea = event.target;

          textarea.style.height = "auto";
          textarea.style.height = textarea.scrollHeight + "px";
        }}
        disabled={disabled}
      />
      <span
        onClick={(event) => {
          if (disabled) return;

          setIsEditing(true);
          setTimeout(() => {
            if (event.target instanceof HTMLElement) {
              if (multiline) {
                inputRef.current.style.height = "auto";
                inputRef.current.style.height =
                  inputRef.current.scrollHeight + "px";
              }
              const pos = event.target.dataset.position;
              inputRef.current.focus();
              inputRef.current.setSelectionRange(pos, pos);
            }
          }, 0);
        }}
        className={className}
        style={{
          display: isEditing ? "none" : "flex",
          flexWrap: "wrap",
          minHeight: "1rem",
          color: disabled ? "#9ca3af" : "inherit",
        }}
        data-name={name}
      >
        {value &&
          value.trim() !== "" &&
          value.split("").map((character, position) => {
            return (
              <span
                data-position={position}
                style={{ flexBasis: character === "\n" ? "100%" : null }}
                key={position}
              >
                {character.trim() === "" ? (
                  character !== "\n" ? (
                    <>&nbsp;</>
                  ) : position + 1 < value.length &&
                    value[position + 1] === "\n" ? (
                    <>&nbsp;</>
                  ) : (
                    <></>
                  )
                ) : (
                  character
                )}
              </span>
            );
          })}
        {((value && value.trim() === "") || !value) && placeholder && (
          <span className={className + " placeholder"}>
            {disabled ? "" : placeholder}
          </span>
        )}
      </span>
    </>
  );
};

export default InlineEdit;
