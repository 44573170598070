import { compareByProcessName, predecessorSort } from "../helper/processes";
import humanId from "../helper/human_id";

export const buildTreeData = (processes, isProcessMap) => {
  const allProcesses = processes || [];

  const addChildren = (parent) => {
    const children = predecessorSort(
      allProcesses
        .filter((process) => process.parent_frontend_id === parent.frontend_id)
        .sort(compareByProcessName)
    );

    return { ...parent, children: children.map((child) => addChildren(child)) };
  };
  const convertToAntdData = (process) => ({
    id: humanId(process.sequential_id, process.process_level),
    title: process.name,
    key: process.frontend_id,
    children: (process.children || []).map((child) => convertToAntdData(child)),
    selectable: !isProcessMap,
    predecessor_frontend_id: process.predecessor_frontend_id,
    frontend_id: process.frontend_id,
  });

  const mainProcesses = predecessorSort(
    allProcesses
      .filter((process) => process.process_level === "main")
      .sort(compareByProcessName)
  ).map((mainProcess) => addChildren(mainProcess));
  const mapIdAndChildren = (process) => [
    process.frontend_id,
    ...(process.children || []).map((child) => mapIdAndChildren(child)),
  ];
  const flatDeep = (arr, d = 1) => {
    return d > 0
      ? arr.reduce(
          (acc, val) =>
            acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val),
          []
        )
      : arr.slice();
  };
  const processFrontendIdsInHierarchy = flatDeep(
    mainProcesses.map(mapIdAndChildren),
    Infinity
  );
  const unfiledProcesses = allProcesses.filter(
    (process) => !processFrontendIdsInHierarchy.includes(process.frontend_id)
  );

  const unfiledNormalProcesses = predecessorSort(
    unfiledProcesses
      .filter((process) => process.process_level === "normal")
      .sort(compareByProcessName)
  );
  const unfiledSubProcesses = predecessorSort(
    unfiledProcesses
      .filter((process) => process.process_level === "sub")
      .sort(compareByProcessName)
  );

  const processData = mainProcesses.map((process) =>
    convertToAntdData(process)
  );

  const treeData = [
    ...processData,
    ...unfiledNormalProcesses
      .concat(unfiledSubProcesses)
      .map(convertToAntdData),
  ];

  return treeData;
};
