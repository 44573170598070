import * as React from "react";
import { UserOutlined } from "@ant-design/icons";
import { Button, Dropdown, DropDownProps, Menu } from "antd";
import ax from "../../helper/ax";
import { connect } from "react-redux";
import withTranslations from "../../helper/withTranslations";

const logout = (path) => () => {
  ax.delete(path)
    .then(() => window.location.replace("/"))
    .catch(() => window.location.replace("/"));
};

const menu = (destroy_user_session_path, logout_text) => () =>
  (
    <Menu>
      <Menu.Item key="logout">
        <a onClick={logout(destroy_user_session_path)}>{logout_text}</a>
      </Menu.Item>
    </Menu>
  );

interface Props {
  placement?: DropDownProps["placement"];
}

const AccountDropDown = ({
  user,
  translations,
  placement = "bottomRight",
  inSidebar = false,
}: Props) => (
  <>
    {user.logged_in && (
      <Dropdown
        overlay={menu(
          user.destroy_user_session_path,
          translations["navigation.logout"]
        )}
        placement={placement}
      >
        <Button
          icon={
            <UserOutlined style={{ fontSize: inSidebar ? "24px" : null }} />
          }
          size={inSidebar ? "large" : null}
          type="text"
          style={{
            color: inSidebar ? "#666" : "#eee",
            fontSize: "14px",
            fontWeight: 500,
            display: "flex",
            alignItems: "center",
          }}
          className="menu-hoverstyle"
        >
          {user.email}
        </Button>
      </Dropdown>
    )}
  </>
);

const mapStateToProps = ({ user }) => ({ user });

export default withTranslations(connect(mapStateToProps)(AccountDropDown));
