import * as React from "react";
import Icon from "@ant-design/icons";
import { SplitVertical, SplitHorizontal } from "./split";
import "./ToggleButton.css";

const ToggleButton = ({ splitDirection, setSplitDirection, disabled }) => (
  <div className="togglebutton-wrapper">
    <div
      className={`togglebutton-button togglebutton-left ${
        splitDirection === "horizontal" ? "togglebutton-unselected" : ""
      }`}
    >
      <Icon
        component={SplitHorizontal}
        className="togglebutton-icon"
        onClick={() => {
          if (!disabled) setSplitDirection("vertical");
        }}
        disabled={disabled}
      />
    </div>
    <div style={{ width: "1px", borderRight: "1px solid #ccc" }}>&nbsp;</div>
    <div
      className={`togglebutton-button togglebutton-right ${
        splitDirection === "vertical" ? "togglebutton-unselected" : ""
      }`}
    >
      <Icon
        component={SplitVertical}
        className="togglebutton-icon"
        onClick={() => {
          if (!disabled) setSplitDirection("horizontal");
        }}
        disabled={disabled}
      />
    </div>
  </div>
);

export default ToggleButton;
