const getCurrentProcessFrontendId = () => {
  const idRegex = /.*\/(processes|processmap)\/(?<id>[^\/]+)\/?.*/;
  const match = idRegex.exec(window.location);

  return match ? (match.groups.id === 'new' ? null : match.groups.id) : null;
};

export {
  getCurrentProcessFrontendId,
};
