import * as React from "react";

interface Props {
  condition: React.ReactNode;
  wrapper: any;
}

const BreakpointWrapper: React.FC<Props> = ({
  condition,
  wrapper,
  children,
}) => {
  return condition ? wrapper(children) : children;
};

export default BreakpointWrapper;
