import * as React from "react";
import withTranslations from "../helper/withTranslations";
import { useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Button, Empty, Spin } from "antd";
import Sipoc from "./Sipoc";
import Api from "../store/api";
import { PrinterOutlined } from "@ant-design/icons";

const ProcessMap = ({
  translations,
  enterprise,
  processes,
  processmapSelection,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [renderedProcesses, setRenderedProcesses] = React.useState(null);

  React.useEffect(() => {
    if (id && processmapSelection.length <= 0) {
      dispatch({
        type: "processmap_selection_change",
        payload: { processFrontendIds: [id] },
      });
    }
  }, [id, processmapSelection]);

  React.useEffect(() => {
    if (!processes) return;

    let processMapShown = true;

    const selectedProcesses = processes.filter((process) =>
      processmapSelection.includes(process.frontend_id)
    );

    Promise.all(
      selectedProcesses.map((child, childIndex) => {
        return Api.fetchSipocElements(
          enterprise.token ?? enterprise.read_token,
          child.frontend_id
        ).then((sipocElements) => {
          const childWithElements = { ...child, sipocElements };

          return {
            position: childIndex,
            child: childWithElements,
          };
        });
      })
    )
      .then((values) => {
        const fetchedProcesses = values.reduce((array, { position, child }) => {
          array[position] = child;

          return array;
        }, Array(selectedProcesses.length));

        if (processMapShown) {
          setRenderedProcesses(fetchedProcesses);
        }
      })
      .catch((e) => console.error(e));

    return () => (processMapShown = false);
  }, [processmapSelection, processes]);

  if (!processes || (!id && processmapSelection.length === 0)) {
    return (
      <Empty
        description={translations["processmap.show.empty"]}
        className="mt-10"
      />
    );
  }

  if (!renderedProcesses) return <Spin size="large" />;

  if (renderedProcesses.length <= 0) {
    return (
      <Empty
        description={translations["processmap.show.empty"]}
        className="mt-10"
      />
    );
  }

  return (
    <>
      <div className="flex justify-end no-print">
        <Button
          type="text"
          icon={
            <PrinterOutlined
              style={{
                fontSize: "16px",
                position: "relative",
                top: "-3px",
              }}
            />
          }
          style={{
            color: "#156BB6",
            fontSize: "14px",
            fontWeight: 500,
            textTransform: "uppercase",
          }}
          onClick={window.print}
        >
          {translations["processes.print"]}
        </Button>
      </div>
      <div className="overflow-y-auto">
        <Sipoc allProcesses={processes} processes={renderedProcesses} />
      </div>
    </>
  );
};

const mapStateToProps = ({ processmapSelection }) => ({ processmapSelection });

export default connect(mapStateToProps)(withTranslations(ProcessMap));
