import * as React from "react";
import { connect, useDispatch } from "react-redux";
import withTranslations from "../helper/withTranslations";
import {
  AntAutoComplete,
  ioAutocomplete,
  scAutocomplete,
} from "../helper/sioc_autocomplete";
import ProcessLabel from "./ProcessLabel/ProcessLabel";
import ElementInput from "./ElementInput/ElementInput";
import humanId from "../helper/human_id";

const isEmpty = (element) => {
  if (!element) return true;
  if (element.name && element.name.trim().length > 0) return false;

  return !element.id;
};

const SipocDataTable = withTranslations(
  ({
    sipocElements,
    enterpriseId,
    processFrontendId,
    translations,
    sipocElementsAutocomplete,
    readOnly,
  }) => {
    const dispatch = useDispatch();

    let sequences = sipocElements || {};
    const data = Object.values(sequences);

    const dispatchElement =
      (enterpriseId, processFrontendId) => (action_type, payload) => {
        dispatch({
          type: action_type,
          payload: {
            enterpriseId,
            processFrontendId,
            ...payload,
          },
        });
      };

    let scAutocompleteOptions: AntAutoComplete[];
    let ioAutocompleteOptions: AntAutoComplete[];

    scAutocompleteOptions = scAutocomplete(sipocElementsAutocomplete);
    ioAutocompleteOptions = ioAutocomplete(sipocElementsAutocomplete);

    return (
      <div className="pt-2 flex flex-wrap gap-4">
        <div className="element-table">
          <div className="mb-1">
            <ProcessLabel
              label={translations["activerecord.models.supplier.one"]}
              tooltip={translations["hints.supplier"]}
            />
          </div>
          <div className="mb-1">
            <ProcessLabel
              label={translations["activerecord.models.input.one"]}
              tooltip={translations["hints.input"]}
            />
          </div>

          {data.map((sequence) => (
            <React.Fragment key={sequence.sequence_frontend_id}>
              <div>
                <ElementInput
                  value={sequence.supplier?.name}
                  description={sequence.supplier?.description}
                  id={humanId(sequence.supplier?.sequential_id, "Supplier")}
                  frontendId={sequence.supplier?.frontend_id}
                  dispatch={dispatchElement(enterpriseId, processFrontendId)}
                  disabled={isEmpty(sequence.supplier)}
                  sequenceFrontendId={sequence.sequence_frontend_id}
                  autocompleteOptions={scAutocompleteOptions}
                  type="Supplier"
                  readOnly={readOnly}
                />
              </div>
              <div>
                <ElementInput
                  value={sequence.input?.name}
                  description={sequence.input?.description}
                  id={humanId(sequence.input?.sequential_id, "Input")}
                  frontendId={sequence.input?.frontend_id}
                  dispatch={dispatchElement(enterpriseId, processFrontendId)}
                  disabled={isEmpty(sequence.input)}
                  datatype={sequence.input?.document_type}
                  sequenceFrontendId={sequence.sequence_frontend_id}
                  autocompleteOptions={ioAutocompleteOptions}
                  type="Input"
                  readOnly={readOnly}
                />
              </div>
            </React.Fragment>
          ))}
        </div>

        <div className="element-table">
          <div className="mb-1">
            <ProcessLabel
              label={translations["activerecord.models.output.one"]}
              tooltip={translations["hints.output"]}
            />
          </div>
          <div className="mb-1">
            <ProcessLabel
              label={translations["activerecord.models.customer.one"]}
              tooltip={translations["hints.customer"]}
            />
          </div>

          {data.map((sequence) => (
            <React.Fragment key={sequence.sequence_frontend_id}>
              <div>
                <ElementInput
                  value={sequence.output?.name}
                  description={sequence.output?.description}
                  id={humanId(sequence.output?.sequential_id, "Output")}
                  frontendId={sequence.output?.frontend_id}
                  dispatch={dispatchElement(enterpriseId, processFrontendId)}
                  datatype={sequence.output?.document_type}
                  disabled={isEmpty(sequence.output)}
                  sequenceFrontendId={sequence.sequence_frontend_id}
                  autocompleteOptions={ioAutocompleteOptions}
                  type="Output"
                  readOnly={readOnly}
                />
              </div>
              <div>
                <ElementInput
                  value={sequence.customer?.name}
                  description={sequence.customer?.description}
                  id={humanId(sequence.customer?.sequential_id, "Customer")}
                  frontendId={sequence.customer?.frontend_id}
                  dispatch={dispatchElement(enterpriseId, processFrontendId)}
                  disabled={isEmpty(sequence.customer)}
                  sequenceFrontendId={sequence.sequence_frontend_id}
                  autocompleteOptions={scAutocompleteOptions}
                  type="Customer"
                  readOnly={readOnly}
                />
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  }
);

const mapStateToProps = ({ sipocElementsAutocomplete }) => ({
  sipocElementsAutocomplete,
});

export default connect(mapStateToProps)(SipocDataTable);
