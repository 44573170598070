import { List } from "antd";
import * as React from "react";
import { Link } from "react-router-dom";

const ReportsIndex = ({ reports }) => (
  <List
    bordered
    dataSource={reports}
    renderItem={(report) => (
      <List.Item>
        <Link to={`/reports/${report.id}`}>{report.name}</Link>
      </List.Item>
    )}
    className="m-10"
  />
);

export default ReportsIndex;
