import * as React from "react";
import { AutoComplete, Radio, RadioChangeEvent, Select, Tag } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import ProcessLabel from "../ProcessLabel/ProcessLabel";
import "./ProcessAttribute.scss";
import withTranslations from "../../helper/withTranslations";
import InlineEdit from "./InlineEdit";

const { Option } = Select;

interface dropdownOptions {
  label: string;
  value: string;
  id?: string;
}

interface Props {
  name?: string;
  value?: string;
  label?: string;
  placeholder?: string;
  tooltip?: string;
  header?: boolean;
  objectid?: string;
  dropdown?: boolean;
  dropdownOptions?: Array<dropdownOptions>;
  dropdownValue?: string;
  radio?: boolean;
  radioOptions?: Array<dropdownOptions>;
  className?: string;
  onChange?: any;
  defaultValue?: any;
  translations: any;
  autoCompleteOptions?: { label: string; value: string }[];
  disabled?: boolean;
  readOnly?: boolean;
}

const ProcessAttribute: React.FC<Props> = React.memo(
  ({
    name = "none",
    value,
    label = undefined,
    placeholder,
    defaultValue,
    tooltip = undefined,
    header = false,
    objectid = undefined,
    dropdown = false,
    dropdownOptions = [],
    radio = false,
    radioOptions = [],
    className = "",
    onChange,
    translations,
    autoCompleteOptions,
    disabled = false,
    readOnly = false,
  }) => {
    placeholder =
      disabled || readOnly
        ? ""
        : placeholder ?? translations["click_here_to_edit"];

    const handleBlur = (value) => value && onChange(value, name);

    const handleSelectChange = React.useCallback(
      (value) => {
        onChange(value, name);
      },
      [onChange, name]
    );
    const handleRadioChange = React.useCallback(
      (event: RadioChangeEvent) => {
        onChange(event.target.value, name);
      },
      [onChange, name]
    );

    return (
      <div className={`${className} test-${name}`}>
        {label && (
          <div>
            <ProcessLabel label={label} tooltip={tooltip} />
          </div>
        )}
        {radio && !readOnly && (
          <div>
            <Radio.Group
              onChange={handleRadioChange}
              value={value ?? defaultValue}
              className="process-level-radios"
            >
              {radioOptions.map((option) => (
                <Radio
                  key={option.id ?? `${name}-${option.value}`}
                  value={option.value}
                >
                  {option.label}
                </Radio>
              ))}
            </Radio.Group>
          </div>
        )}
        {dropdown && !readOnly && (
          <div>
            <Select
              defaultValue={value ?? defaultValue}
              placeholder={placeholder}
              style={{}}
              onChange={handleSelectChange}
              className="process-dropdown"
              bordered={false}
              suffixIcon={<CaretDownOutlined />}
              dropdownMatchSelectWidth={false}
              disabled={disabled}
            >
              {dropdownOptions.map((option) => (
                <Option key={option.id ?? -1} value={option.value}>
                  {option.id && <Tag className="tag-id">{option.id}</Tag>}
                  {option.label}
                </Option>
              ))}
            </Select>
          </div>
        )}
        {!dropdown && !radio && (
          <div
            style={{ display: "flex" }}
            className={
              header ? "processattribute-large" : "processattribute-normal"
            }
          >
            {objectid && <Tag className="tag-id">{objectid}</Tag>}
            {!autoCompleteOptions && (
              <InlineEdit
                name={name}
                defaultValue={value}
                className="editableText"
                onBlur={handleBlur}
                autoComplete={name === "name" ? "off" : undefined}
                placeholder={placeholder}
                multiline={name === "description"}
                disabled={disabled || readOnly}
              />
            )}
            {autoCompleteOptions && (
              <AutoComplete
                options={autoCompleteOptions}
                defaultValue={value}
                onChange={handleBlur}
                style={{ width: "100%" }}
                className="editableText"
                placeholder={placeholder}
                getPopupContainer={(triggerNode: HTMLElement) =>
                  triggerNode.parentNode as HTMLElement
                }
                disabled={disabled || readOnly}
              />
            )}
          </div>
        )}
      </div>
    );
  }
);

export default withTranslations(ProcessAttribute);
