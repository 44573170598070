import * as React from "react";
import { Empty, Spin } from "antd";
import { connect, useDispatch } from "react-redux";
import withTranslations from "../helper/withTranslations";
import { Route, Routes } from "react-router-dom";
import ReportsIndex from "./ReportsIndex";
import ReportsShow from "./ReportsShow";

const ReportsRouter = ({ translations, reports, enterprises }) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (reports) return;

    dispatch({
      type: "reports_fetch",
      payload: {
        enterpriseId: enterprises.current.token,
      },
    });
  }, []);

  if (!reports) return <Spin size="large" className="m-4" />;

  if (reports.length <= 0) {
    return (
      <Empty
        description={translations["reports.index.empty.message"]}
        className="mt-10"
      />
    );
  }

  return (
    <Routes>
      <Route path="*" element={<ReportsIndex reports={reports} />} />
      <Route path=":id" element={<ReportsShow />} />
    </Routes>
  );
};

export default connect(({ reports, enterprises }) => ({
  reports,
  enterprises,
}))(withTranslations(ReportsRouter));
