import * as React from "react";

const ValueChain = ({ width = "1em", height = "1em" }) => {
  const w = 24;
  const h = 10;
  const strokeWidth = 2;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`-${strokeWidth * 2} -${strokeWidth * 2} ${
        w + strokeWidth * 4
      } ${h + strokeWidth * 2}`}
      width={width}
      height={height}
    >
      <path
        d={`M 0,0 ${w * 0.1},${h / 2} 0,${h} ${w * 0.9},${h} ${w},${h / 2} ${
          w * 0.9
        },0 Z`}
        fill="currentColor"
        fillOpacity="0"
        stroke="currentColor"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export default ValueChain;
