import { combineReducers } from "redux";
import Const from "../constants/frontendConstants";
import translationsAllLocales from "../locales/translations.json";
import update from "immutability-helper";
import {
  emptyElement,
  ensureBlankRow,
  replaceSipocElement,
  transformSequencesToFrontend,
} from "../helper/sequences";
import {
  FrontendSequence,
  FrontendSipocElement,
  Sequences,
  SIOCAutoComplete,
  SIOCKey,
} from "../helper/types";
import produce from "immer";

const user = (state = null, _action) => {
  return state;
};

const theme = (state = null, _action) => state;
const enterprises = (state = null, _action) => state;
const processes = (state = null, action) => {
  switch (action.type) {
    case "processes_fetched":
      return action.processes;
    case "process_updated":
      return produce(state || [], (draft) => {
        const processIndex = draft.findIndex(
          (process) => process.frontend_id === action.process.frontend_id
        );

        if (processIndex >= 0) {
          draft[processIndex] = { ...draft[processIndex], ...action.process };
        } else {
          draft.push(action.process);
        }
      });
    case "process_deleted":
      const removeProcessIndex = state.findIndex(
        (process) => process.frontend_id === action.processFrontendId
      );

      return update(state, { $splice: [[removeProcessIndex, 1]] });
    default:
      return state;
  }
};

const sipocElements = (state: Sequences | null = null, action) => {
  switch (action.type) {
    case "sipocelement_updated":
      return ensureBlankRow(
        transformSequencesToFrontend(
          replaceSipocElement(state, action.sipocElement)
        )
      );
    case "sipocelements_fetched": {
      if (action.payload.readOnly) {
        return transformSequencesToFrontend(action.payload.sipocElements);
      } else {
        return ensureBlankRow(
          transformSequencesToFrontend(action.payload.sipocElements)
        );
      }
    }
    case "sipocelement_deleted":
      const deleted_id = action.payload.frontend_id;

      const frontendSequences = Object.values(
        transformSequencesToFrontend(state)
      );
      const allElements: FrontendSipocElement[] = frontendSequences
        .map((sequence: FrontendSequence) => [
          sequence.supplier,
          sequence.input,
          sequence.output,
          sequence.customer,
        ])
        .flat();

      const sipocElement: FrontendSipocElement = allElements.find(
        (element: FrontendSipocElement) => element.frontend_id === deleted_id
      );

      const newEmptyElement = emptyElement(
        sipocElement.type as SIOCKey,
        sipocElement.sequence_frontend_id,
        sipocElement.sequence
      );

      return replaceSipocElement(state, sipocElement, newEmptyElement);
    case "process_switch":
      return null;
    default:
      return state;
  }
};
const sipocElementsAutocomplete = (state: SIOCAutoComplete[] = [], action) => {
  switch (action.type) {
    case "sipocelements_autocomplete_fetched":
      return action.payload;
    default:
      return state;
  }
};
const eventsAutocomplete = (state = [], action) => {
  switch (action.type) {
    case "events_autocomplete_fetched":
      return action.payload;
    default:
      return state;
  }
};
const processmapSelection = (state = [], action) => {
  switch (action.type) {
    case "processmap_selection_change":
      return action.payload.processFrontendIds;
    default:
      return state;
  }
};
const reports = (state = null, action) => {
  switch (action.type) {
    case "reports_fetched":
      return action.payload;
  }

  return state;
};

const syncStatus = (state = [], action) => {
  switch (action.type) {
    case "apirequest_started":
      return [...state, action.payload];
    case "apirequest_stopped":
      return state.filter(
        (requestInProgress) =>
          requestInProgress.timestamp !== action.payload.timestamp
      );
    default:
      return state;
  }
};
const translations = (state = translationsAllLocales["en"], action) => state;
const error = (state = false, action) => {
  switch (action.type) {
    case "error":
      return state ? state : true;
    default:
      return !state ? state : false;
  }
};

const frontendReducer = combineReducers({
  theme,
  user,
  enterprises,
  processes,
  translations,
  sipocElements,
  sipocElementsAutocomplete,
  syncStatus,
  processmapSelection,
  eventsAutocomplete,
  reports,
  error,
});

export default frontendReducer;
