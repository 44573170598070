import ax from "../helper/ax";

const fetchProcesses = async (enterpriseId) => {
  const response = await ax.get(
    `/api/v1/enterprises/${enterpriseId}/processes`
  );

  return response.data;
};

const updateProcess = async (enterpriseId, process) => {
  const response = await ax.patch(
    `/api/v1/enterprises/${enterpriseId}/processes/${process.frontend_id}`,
    process
  );

  return response.data;
};

const deleteProcess = async (enterpriseId, processFrontendId) => {
  const response = await ax.delete(
    `/api/v1/enterprises/${enterpriseId}/processes/${processFrontendId}`
  );

  return response.data;
};

const createSipocElement = async (enterpriseId, processFrontendId, element) => {
  const response = await ax.post(
    `/api/v1/enterprises/${enterpriseId}/processes/${processFrontendId}/sipoc_elements`,
    element
  );

  return response.data;
};

const updateSipocElement = async (enterpriseId, processFrontendId, element) => {
  const response = await ax.patch(
    `/api/v1/enterprises/${enterpriseId}/processes/${processFrontendId}/sipoc_elements/${element.frontend_id}`,
    element
  );

  return response.data;
};

const fetchSipocElements = async (enterpriseId, processFrontendId) => {
  const response = await ax.get(
    `/api/v1/enterprises/${enterpriseId}/processes/${processFrontendId}/sipoc_elements`
  );

  return response.data;
};

const deleteSipocElement = async (
  enterpriseId,
  processFrontendId,
  sipocElementId
) => {
  const response = await ax.delete(
    `/api/v1/enterprises/${enterpriseId}/processes/${processFrontendId}/sipoc_elements/${sipocElementId}`
  );

  return response.data;
};

const updateEvent = async (enterpriseId, processFrontendId, event) => {
  const response = await ax.patch(
    `/api/v1/enterprises/${enterpriseId}/processes/${processFrontendId}/event`,
    { event }
  );

  return response.data;
};

const deleteEvent = async (enterpriseId, processFrontendId, eventType) => {
  const response = await ax.delete(
    `/api/v1/enterprises/${enterpriseId}/processes/${processFrontendId}/events/${eventType}`
  );

  return response.data;
};

const fetchSipocElementsAutocomplete = async (enterpriseId) => {
  const response = await ax.get(
    `/api/v1/enterprises/${enterpriseId}/processes/none/sipoc_elements/autocomplete`
  );

  return response.data;
};

const fetchEventsAutocomplete = async (enterpriseId, process_level) => {
  const response = await ax.get(
    `/api/v1/enterprises/${enterpriseId}/events/autocomplete?process_level=${process_level}`
  );

  return response.data;
};

const fetchReports = async (enterpriseId) => {
  const response = await ax.get(`/api/v1/enterprises/${enterpriseId}/reports`);

  return response.data;
};

export default {
  fetchProcesses,
  updateProcess,
  deleteProcess,
  createSipocElement,
  updateSipocElement,
  fetchSipocElements,
  deleteSipocElement,
  fetchSipocElementsAutocomplete,
  fetchEventsAutocomplete,
  updateEvent,
  deleteEvent,
  fetchReports,
};
