import React from "react";

// export default () => <svg fill="currentColor">
//     <path fill="#ffffff" fillOpacity="0" stroke="currentColor" stroke-width="3" d="M 0,0 0,63 c 0,0 38,9 61,8 C 110.79252336448597,75 143.73084112149533,43.5 213.6,45 L 213.6,0 z"></path>
// </svg>
//export default () => <svg fill="currentColor"><path fill="currentColor" fillOpacity="0" stroke="currentColor" stroke-width="3" d="M 0,0 0,63 c 0,0 38,9 61,8 C 110.79252336448597,75 143.73084112149533,43.5 213.6,45 L 213.6,0 z"/></svg>

// export default () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 -3 219.6 77">
// <path d="M 0,0 0,63 c 0,0 38,9 61,8 C 110.79252336448597,75 143.73084112149533,43.5 213.6,45 L 213.6,0 z" stroke="#FF0000" stroke-width="3" fill="#000000"/>
// </svg>

// M0 0v63s38 9 61 8c49.793 4 82.73-27.5 152.6-26V0z

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-3 -3 219.6 77"
    width="1em"
    height="1em"
  >
    <path
      d="M0 0v63s38 9 61 8c49.793 4 82.73-27.5 152.6-26V0z"
      fill="currentColor"
      fillOpacity="0"
      stroke="currentColor"
      strokeWidth={18}
    />
  </svg>
);
