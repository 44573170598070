import React, { useState, useEffect } from "react";
import { Tag, Tree } from "antd";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { getCurrentProcessFrontendId } from "../../helper/location";
import { buildTreeData } from "../../helper/tree";
import "./ProcessTree.css";
import { CaretDownOutlined } from "@ant-design/icons";

const renderTitle = (values: any) => {
  return (
    <div style={{ marginTop: "4px" }}>
      <Tag className="tag-id" style={{ marginRight: "0px" }}>
        {values.id}
      </Tag>
      <span style={{ marginLeft: "4px" }}>{values.title}</span>
    </div>
  );
};

const ProcessTree = ({ processes, processmapSelection, isProcessMap }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [treeData, setTreeData] = useState(
    buildTreeData(processes, isProcessMap)
  );
  useEffect(() => {
    setTreeData(buildTreeData(processes, isProcessMap));
  }, [processes, isProcessMap]);

  const mapKey = (node) => [node.key, ...node.children.map(mapKey)].flat();
  const allKeys = treeData.map(mapKey).flat();
  const showProcess = (selectedKeys) => {
    if (isProcessMap) return;

    const processFrontendId = selectedKeys[0];

    if (!processFrontendId) return;

    if (!isProcessMap) {
      dispatch({ type: "process_switch", payload: { processFrontendId } });
      navigate(`/processes/${processFrontendId}`);
    }
  };

  const processFrontendId = getCurrentProcessFrontendId();
  const onCheck = (checkedKeys, info) => {
    let processFrontendIds = checkedKeys.checked;

    if (info.checked) {
      const newProcessFrontendId = info.node.key;
      const oldProcessFrontendIds = checkedKeys.checked.filter(
        (id) => id !== newProcessFrontendId
      );
      const newProcess = processes.find(
        (process) => process.frontend_id === newProcessFrontendId
      );
      const oldProcesses = processes.filter((process) =>
        oldProcessFrontendIds.includes(process.frontend_id)
      );

      if (oldProcesses.length > 0) {
        const oldProcessLevel = oldProcesses[0].process_level;
        const otherProcessLevels = oldProcesses.filter(
          (process) => process.process_level !== oldProcessLevel
        );

        if (otherProcessLevels.length > 0) {
          throw {
            message: "Selected processes have different process levels.",
            oldProcesses,
            checkedKeys,
            info,
          };
        }

        if (newProcess.process_level !== oldProcessLevel) {
          processFrontendIds = [newProcessFrontendId];
        }
      }
    }

    dispatch({
      type: "processmap_selection_change",
      payload: { processFrontendIds },
    });
  };

  // return flattened list of all keys in tree
  const flattenVisibleTree = (tree: Array) => {
    let flatTree = [];
    tree.forEach((node) => {
      flatTree.push(node);
      if (node.children) {
        flatTree = flatTree.concat(flattenVisibleTree(node.children));
      }
    });
    return flatTree;
  };

  const hoverProcess = ({ event, node }) => {
    // console.log('Hover', node)
    // console.log('Tree', treeRef)
    setTreeData(() => {
      // console.log(treeData)
      let newTreeData = [...treeData]; // copying the old tree array
      let flatTree = flattenVisibleTree(newTreeData);
      // console.log(flatTree)
      let reverseFlatTree = flatTree.reverse();
      let started = false;
      let ended = false;
      for (const flatnode of reverseFlatTree) {
        // console.log('node', node, flatnode)
        if (!node) {
          // mouseLeave class to reset everything
          flatnode.className = "";
          continue;
        }
        let level = node.pos.split("-").length - 1;
        if (
          flatnode.frontend_id === node?.frontend_id &&
          node?.predecessor_frontend_id
        ) {
          started = true;
          // set start class
          flatnode.className = `predecessor-end-level-${level}`;
        } else {
          if (flatnode.frontend_id === node?.predecessor_frontend_id) {
            ended = true;
            // set end class
            flatnode.className = "predecessor-start";
            break;
          }
          if (started) {
            // set middle class
            flatnode.className = `predecessor-middle-level-${level}`;
            continue;
          }
        }
      }
      return newTreeData;
    });
  };

  const leaveProcess = ({ event, node }) => {
    // console.log('Leave', node)
    hoverProcess({ event, node: null });
  };

  const selectedKeys =
    !isProcessMap && processFrontendId ? [processFrontendId] : [];
  return (
    <Tree
      treeData={treeData}
      onSelect={showProcess}
      onCheck={onCheck}
      defaultExpandAll={true}
      showLine={{ showLeafIcon: false }}
      showIcon={true}
      expandedKeys={allKeys}
      checkable={isProcessMap}
      checkedKeys={isProcessMap ? processmapSelection : []}
      checkStrictly={true}
      selectedKeys={selectedKeys}
      className="processtree"
      showLine={{ showLeafIcon: false }}
      titleRender={renderTitle}
      blockNode={true}
      switcherIcon={<CaretDownOutlined />}
      // disable arrows shown on processtree item hover until properly implemented
      // onMouseEnter={hoverProcess}
      // onMouseLeave={leaveProcess}
    />
  );
};

const mapStateToProps = ({ processmapSelection }) => ({ processmapSelection });

export default connect(mapStateToProps)(ProcessTree);
