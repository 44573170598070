import * as React from "react";
import { useLocation } from "react-router-dom";
import { Button } from "antd";
import "./Navigation.css";
import withTranslations from "../../helper/withTranslations";
import { connect } from "react-redux";

interface Props {
  inSidebar: boolean;
  setVisible?: any;
}

const activeClass = "menu-active";
const inactiveClass = "menu-inactive";

const GuestNavigation: React.FC<Props> = ({
  inSidebar = false,
  setVisible,
  translations,
  user,
}) => {
  const location = useLocation();
  const sidebarClass = inSidebar ? "menu-sidebar" : "menu-header";
  const buttonProps: any = {};
  if (inSidebar) {
    buttonProps["onClick"] = () => inSidebar && setVisible(false);
    buttonProps["size"] = "large";
  }
  buttonProps["style"] = { textTransform: "uppercase" };

  let selectedMenuItem;
  if (location.pathname.startsWith("/users/sign_up"))
    selectedMenuItem = "signup";
  if (location.pathname.startsWith("/users/sign_in"))
    selectedMenuItem = "login";

  const enterpriseTokenRegex = new RegExp("/enterprises/([^/]+)");
  const enterpriseTokenMatches = enterpriseTokenRegex.exec(
    window.location.pathname
  );
  const enterpriseToken =
    enterpriseTokenMatches && enterpriseTokenMatches.length > 1
      ? enterpriseTokenMatches[1]
      : "";

  return (
    <React.Fragment>
      {selectedMenuItem !== "signup" && (
        <div
          className={`${
            selectedMenuItem === "signup" ? activeClass : inactiveClass
          } ${sidebarClass} ${!inSidebar && "ml-8"} `}
        >
          <a href={`${user.signup_path}?enterprise_token=${enterpriseToken}`}>
            <Button type="text" {...buttonProps}>
              {translations["devise.shared.signup"]}
            </Button>
          </a>
        </div>
      )}
      {selectedMenuItem !== "login" && (
        <div
          className={`${
            selectedMenuItem === "login" ? activeClass : inactiveClass
          } ${sidebarClass}`}
        >
          <a href={user.login_path}>
            <Button type="text" {...buttonProps}>
              {translations["devise.shared.login"]}
            </Button>
          </a>
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps =
  () =>
  ({ user }) => ({ user });

export default withTranslations(connect(mapStateToProps)(GuestNavigation));
