import React from 'react';
import { Alert, Button } from 'antd';
import withTranslations from '../helper/withTranslations';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.translations = props.translations;
    this.state = { hasError: false };
    this.airbrake = window.airbrake;
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    this.airbrake.notify({
      error: error,
      params: { info: info }
    });
  }

  render() {
    if (this.state.hasError) {
      return <Alert
        message={this.translations['errors.unknown.title']}
        description={this.translations['errors.unknown.description']}
        type="error"
        showIcon
        action={
          <Button danger onClick={() => location.reload()}>
            {this.translations['errors.unknown.refresh']}
          </Button>
        }
      />;
    }

    return this.props.children;
  }
}

export default withTranslations(ErrorBoundary);
