import { DownOutlined } from "@ant-design/icons";
import { Button, Divider, Dropdown, Menu } from "antd";
import * as React from "react";
import { connect } from "react-redux";
import withTranslations from "../helper/withTranslations";
import { isLite } from "../helper/lite";

const switchToEnterprise = (enterprise) => () => {
  window.location = `/enterprises/${enterprise.token}/frontend`;
};

const enterpriseDropdown = (enterprises, current, theme, translations) => () =>
  (
    <Menu>
      {enterprises
        .sort((first, second) => {
          if (first.name < second.name) return -1;
          else if (first.name > second.name) return 1;
          return 0;
        })
        .map((enterprise) => (
          <Menu.Item key={enterprise.id}>
            <a
              style={{
                color: enterprise.id === current.id ? theme.primary : "inherit",
              }}
              onClick={switchToEnterprise(enterprise)}
            >
              {enterprise.name}
            </a>
          </Menu.Item>
        ))}
      <Divider style={{ marginTop: "0.25rem", marginBottom: "0.25rem" }} />
      <Menu.Item key="manage">
        <a href="/enterprises">{translations["enterprises.manage"]}</a>
      </Menu.Item>
    </Menu>
  );

const EnterpriseSwitch = ({ user, enterprises, theme, translations }) => {
  if (isLite(user)) {
    return (
      <Button type="text" className="font-bold text-base text-gray-600">
        {enterprises.current.name}
      </Button>
    );
  }

  return (
    <div>
      {enterprises && enterprises.current && (
        <Dropdown
          overlay={enterpriseDropdown(
            enterprises.all,
            enterprises.current,
            theme,
            translations
          )}
          placement="bottomRight"
        >
          <Button type="text" className="font-bold text-base text-gray-600">
            {enterprises.current.name}
            <DownOutlined
              style={{
                marginLeft: "12px",
                fontSize: "12px",
                paddingTop: "3px",
              }}
            />
          </Button>
        </Dropdown>
      )}
    </div>
  );
};

const mapStateToProps = ({ user, enterprises, theme }) => ({
  user,
  enterprises,
  theme,
});

export default withTranslations(connect(mapStateToProps)(EnterpriseSwitch));
