import * as React from "react";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import Navigation from "./Navigation/Navigation";
import { connect } from "react-redux";
import ProcessTree from "./ProcessTree/ProcessTree";
import EnterpriseSwitch from "./EnterpriseSwitch";
import NewProcessButton from "./Navigation/NewProcessButton";
import GuestNavigation from "./Navigation/GuestNavigation";
import AccountDropDown from "./Navigation/AccountDropDown";
import { useLocation } from "react-router-dom";
import { Enterprises, Process, User } from "../helper/types";

interface Props {
  setVisible?: any;
  enterprises: Enterprises;
  processes: Process[];
  user: User;
  readOnly: boolean;
}

const Sidebar: React.FC<Props> = ({
  setVisible,
  processes,
  enterprises,
  user,
  readOnly,
}) => {
  const location = useLocation();

  return (
    <div className="flex flex-col flex-auto bg-gray-100 floating-menu md:floating-menu-disabled overflow-y-auto overflow-x-hidden no-print">
      <div className="flex flex-col md:hidden">
        <div className="flex" style={{ alignItems: "center" }}>
          <div style={{ flex: "1 1 auto" }}>
            {!readOnly && (
              <NewProcessButton
                setVisible={setVisible}
                size="large"
                className="m-2"
              />
            )}
          </div>
          <div className="mr-2" style={{ justifyContent: "end" }}>
            <Button
              type="text"
              size="large"
              onClick={() => setVisible(false)}
              icon={<CloseOutlined style={{ fontSize: "22px" }} />}
              style={{ color: "#000", fontSize: "22px" }}
            />
          </div>
        </div>
        {user.logged_in && enterprises && enterprises.current && (
          <Navigation inSidebar={true} setVisible={setVisible} />
        )}
        {!user.logged_in && (
          <GuestNavigation inSidebar={true} setVisible={setVisible} />
        )}
      </div>
      {!readOnly && (
        <div className="flex items-center pl-2 pt-4 pb-4 pr-4">
          <EnterpriseSwitch />
        </div>
      )}
      <div style={{ flex: "1 1 auto" }}>
        <ProcessTree
          processes={processes}
          isProcessMap={location.pathname.startsWith("/processmap")}
        />
      </div>
      <div
        className="flex items-center lg:hidden"
        style={{
          borderTop: "1px solid #ddd",
        }}
      >
        <AccountDropDown placement="topLeft" inSidebar={true} />
      </div>
    </div>
  );
};

const mapStateToProps = ({ processes, enterprises, user }) => ({
  processes,
  enterprises,
  user,
});
export default connect(mapStateToProps)(Sidebar);
