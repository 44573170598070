import "./wdyr";
import { enableAllPlugins } from "immer";

import "../css/frontend.scss";

import React from "react";
import ReactOnRails from "react-on-rails";
import { connect } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Layout } from "antd";

import FrontendApp from "../bundles/frontend/startup/FrontendApp";
import Header from "../bundles/frontend/components/Header";
import withStore from "../bundles/frontend/helper/withStore";

import "../support/airbrake";

enableAllPlugins();

ReactOnRails.register({
  FrontendApp,
  Header: withStore(
    connect((state) => state)((props) => (
      <BrowserRouter>
        <Layout>
          <Header {...props} />
        </Layout>
      </BrowserRouter>
    ))
  ),
});
