import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import frontendReducer from '../reducers/frontendReducer';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();
const configureStore = (railsProps) => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(frontendReducer, railsProps, composeEnhancers(applyMiddleware(sagaMiddleware)));

  sagaMiddleware.run(sagas);

  return store;
}

export default configureStore;
