import * as React from "react";

const Data = ({ width = "1em", height = "1em" }) => {
  const w = 24;
  const h = 10;
  const strokeWidth = 2;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`-${strokeWidth * 2} -${strokeWidth * 2} ${
        w + strokeWidth * 4
      } ${h + strokeWidth * 2}`}
      width={width}
      height={height}
    >
      <path
        d={`M 0,${h} ${w * 0.9},${h} ${w},0 ${w * 0.1},0 Z`}
        fill="currentColor"
        fillOpacity="0"
        stroke="currentColor"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export default Data;
